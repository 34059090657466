import { useRecoilValue } from 'recoil'
import useLogout from '../../../../../hooks/utils/useLogout'
import { userInfoState } from '../../../../../state/state'
import Button from '../../../../library/Button/Button'
import TableColumn from '../../../../library/Table/TableColumn'
import TableValue from '../../../../library/Table/TableValue'
import TableContainer from '../../../../library/Table/TableContainer'
import TableLabel from '../../../../library/Table/TableLabel'

const AccountSettings = () => {
  const logout = useLogout()

  const info = useRecoilValue(userInfoState)

  return (
    <div className="w-full flex flex-col items-start justify-start gap-8">
      <div className="text-[20px] font-medium text-text-1">Account Info</div>
      <TableContainer className="max-h-[400px] overflow-y-auto">
        <TableColumn className="min-w-[100px] flex-none">
          <TableLabel last={true}>Email</TableLabel>
          <TableLabel last={true}>Password</TableLabel>
        </TableColumn>
        <TableColumn className="flex-1">
          <TableValue last={true}>{info?.email}</TableValue>
          <TableValue last={true}>************</TableValue>
        </TableColumn>
      </TableContainer>
      <Button variant="cta" onClick={logout}>
        Logout
      </Button>
    </div>
  )
}

export default AccountSettings
