import { useRecoilState } from 'recoil'
import useJoinOrganization from './useJoinOrganization'
import { joinOrganizationTokenState } from '../../state/state'

const useJoinToken = () => {
  const [token, setToken] = useRecoilState(joinOrganizationTokenState)
  const joinOrganization = useJoinOrganization()

  return async () => {
    if (token) {
      await joinOrganization(token)
      setToken(null)
      return true
    }
    return false
  }
}

export default useJoinToken
