import { useEffect, useState } from 'react'
import useBackend from '../services/useBackend'
import useNav, { LinkKey } from './useNav'
import useAuthService from '../services/useAuthService'

const useLoggedIn = () => {
  const backend = useBackend()
  const auth = useAuthService()
  const navigate = useNav()

  const [loggedIn, setLoggedIn] = useState(true)

  useEffect(() => {
    const checkLoggedIn = async () => {
      try {
        await backend.refresh().then(() => {
          navigate(LinkKey.Logs)
        })
      } catch (e) {
        setLoggedIn(false)
        auth.clearUserToken()
      }
    }
    checkLoggedIn()
  }, [auth, backend, navigate])

  return loggedIn
}

export default useLoggedIn
