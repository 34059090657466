import { useState } from 'react'
import Button from '../../../../../library/Button/Button'
import useCreateToken from '../../../../../../hooks/actions/useCreateToken'
import Step from '../../../../../library/Step/Step'
import CodeBlock from '../../../../../library/CodeBlock/CodeBlock'

interface PythonDocsProps {
  close: () => void
}

const url = process.env.REACT_APP_LOG_ADDRESS || 'log.vigilant.run:4317'

const PythonDocs: React.FC<PythonDocsProps> = ({ close }) => {
  const createToken = useCreateToken()
  const [token, setToken] = useState('')

  const handleCreateToken = async () => {
    const token = await createToken('Python SDK Token', 'Token for Python SDK.')
    if (token) setToken(token.token)
  }

  return (
    <div className="flex flex-col w-full max-w-[800px] p-8">
      <div className="w-full h-full flex flex-col items-start justify-start">
        <div className="w-full flex flex-row items-center justify-between mb-4">
          <div className="text-[24px] font-semibold text-text-1">
            Python Setup
          </div>
          <Button variant="outline" onClick={close}>
            Back
          </Button>
        </div>
        <p className="text-[14px] text-text-1 leading-6 mb-8">
          Follow these instructions to setup the Vigilant Python SDK.
        </p>

        <Step number={1} title="Install the SDK">
          <p className="text-[14px] text-text-1 leading-6">
            Install the SDK using the following command:
          </p>
          <CodeBlock
            displayName={'Bash'}
            language={'bash'}
            children={'pip install vigilant-py'}
          />
        </Step>

        <Step number={2} title="Create a Token">
          <p className="text-[14px] text-text-1 leading-6">
            Create a secret token to authenticate the SDK with Vigilant.
          </p>
          <div className="flex flex-col items-start justify-start">
            <Button
              disabled={!!token}
              variant="cta"
              onClick={handleCreateToken}
            >
              Create Token
            </Button>
          </div>
        </Step>

        <Step number={3} title="Use the SDK" disabled={!token}>
          <p className="text-[14px] text-text-1 leading-6">
            Use the SDK to send logs to Vigilant.
          </p>
          <CodeBlock
            displayName={'Python'}
            language={'python'}
            children={getCodeSnippet(url, token)}
          />
        </Step>

        <Step number={4} title="Run your application" disabled={!token}>
          <p className="text-[14px] text-text-1 leading-6">
            Run your application and you should start seeing logs in Vigilant.
          </p>
        </Step>

        <div className="w-full h-[100px]"></div>
      </div>
    </div>
  )
}

export default PythonDocs

function getCodeSnippet(url: string, token: string) {
  return `from vigilant import create_autocapture_logger

# Initialize the logger
logger = create_autocapture_logger(
    url="${url}",
    name="my-service",
    token="${token}",
)

# Enable the logger
logger.enable()

# Log a message using the logger
logger.info('You can log messages like this')

# Log a message using print
print('But you can also use print')
`
}
