import { useState } from 'react'
import useNav, { LinkKey } from '../../../hooks/utils/useNav'
import OnboardingCard from '../../library/OnboardingCard/OnboardingCard'
import Button from '../../library/Button/Button'
import LabeledInput from '../../library/LabeledInput/LabledInput'
import useLoggedOut from '../../../hooks/utils/useLoggedOut'
import useInitAdminData from '../../../hooks/data/effects/useInitAdminData'
import useCreateProject from '../../../hooks/actions/useCreateProject'

const NewProject: React.FC = () => {
  const loggedOut = useLoggedOut()
  useInitAdminData()

  const navigate = useNav()
  const createProject = useCreateProject()

  const [name, setName] = useState('')

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    await createProject(name)
    navigate(LinkKey.Logs)
  }

  if (loggedOut) return <></>

  return (
    <div className="h-screen w-screen flex items-center justify-center bg-background">
      <OnboardingCard>
        <form onSubmit={handleSubmit} className="w-full flex flex-col gap-6">
          <div className="w-full flex flex-col items-start justify-center gap-1.5">
            <h2 className="text-[24px] font-semibold text-text-1 mb-2">
              Create a new project
            </h2>
            <p className="text-[14px] text-text-2 leading-6 mb-2">
              Use projects to isolate logs and integrations from each other. You
              can have multiple projects in an organization.
            </p>
            <p className="text-[14px] text-text-2 leading-6">
              <span className="font-semibold">
                We recommend using a project for each environment (e.g. dev,
                production).
              </span>
            </p>
          </div>
          <div className="w-full flex flex-col items-start justify-center gap-4">
            <LabeledInput
              label={'Project Name'}
              required={true}
              placeholder={'Enter your project name'}
              value={name}
              type={'text'}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <Button type={'submit'} className={'w-full'}>
            Create
          </Button>
        </form>
      </OnboardingCard>
    </div>
  )
}

export default NewProject
