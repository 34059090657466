import { useRecoilValue } from 'recoil'
import { organizationIdState, projectsState } from '../../state/state'
import useBackend from '../services/useBackend'
import useLoadProjects from '../data/load/useLoadProjects'
import useSwitchProject from './useSwitchProject'

const useDeleteProject = () => {
  const backend = useBackend()
  const organizationId = useRecoilValue(organizationIdState)
  const projects = useRecoilValue(projectsState)

  const loadProjects = useLoadProjects()
  const switchProject = useSwitchProject()

  const deleteProject = async (projectId: string) => {
    if (!organizationId) return
    try {
      await backend.deleteProject(projectId)
      await loadProjects()
      if (!projects || projects.length === 0) return
      const newProject = projects.find((p) => p.id !== projectId)
      if (!newProject) return
      await switchProject(newProject.id)
    } catch (e) {}
  }

  return deleteProject
}

export default useDeleteProject
