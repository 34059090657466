import { useEffect } from 'react'
import useLoadOrganizationInfo from '../load/useLoadOrganizationInfo'
import useLoadOrganizations from '../load/useLoadOrganizations'
import useLoadProjects from '../load/useLoadProjects'
import useLoadUserInfo from '../load/useLoadUserInfo'

const useInitAdminData = () => {
  const loadUserInfo = useLoadUserInfo()
  const loadOrganizations = useLoadOrganizations()
  const loadOrganizationInfo = useLoadOrganizationInfo()
  const loadProjects = useLoadProjects()

  useEffect(() => {
    loadUserInfo()
  }, [loadUserInfo])

  useEffect(() => {
    loadOrganizations()
  }, [loadOrganizations])

  useEffect(() => {
    loadOrganizationInfo()
  }, [loadOrganizationInfo])

  useEffect(() => {
    loadProjects()
  }, [loadProjects])
}

export default useInitAdminData
