import useBackend from '../services/useBackend'
import useNav, { LinkKey } from '../utils/useNav'
import useSelectOrganization from './useSelectOrganization'
import useSelectProject from './useSelectProject'
import useJoinToken from './useJoinToken'

const useLogin = () => {
  const backend = useBackend()
  const navigate = useNav()

  const selectOrganization = useSelectOrganization()
  const selectProject = useSelectProject()
  const joinToken = useJoinToken()

  return async (email: string, password: string) => {
    try {
      await backend.login(email, password)

      const joined = await joinToken()
      if (joined) {
        navigate(LinkKey.Logs)
        return
      }

      const { organizations } = await backend.getOrganizations()
      if (!organizations || organizations.length === 0) {
        navigate(LinkKey.NewOrganization)
        return
      }

      const organizationId = organizations[0].id
      await selectOrganization(organizationId)

      const { projects } = await backend.getProjects(organizationId)
      if (!projects || projects.length === 0) {
        navigate(LinkKey.NewProject)
        return
      }

      const projectId = projects[0].id
      await selectProject(organizationId, projectId)

      navigate(LinkKey.Logs)
    } catch (e) {}
  }
}

export default useLogin
