export type TimeRange =
  | [string | null, string | null]
  | '30s'
  | '5m'
  | '15m'
  | '1h'
  | '6h'
  | '24h'
  | '3d'
  | '7d'
  | 'all'

export function getTimestamps(
  timeRange: TimeRange,
): [string | undefined, string | undefined] {
  if (typeof timeRange === 'string') {
    return [timeRangeToTimestamp(timeRange), undefined]
  } else if (Array.isArray(timeRange)) {
    const first = parseTime(timeRange[0])
    const second = parseTime(timeRange[1])
    return [first, second]
  }
  return [undefined, undefined]
}

function timeRangeToTimestamp(timeRange: TimeRange): string | undefined {
  const now = new Date()
  const millis = timeRangeToMillis(timeRange)
  if (millis === undefined) return undefined

  const startTime = new Date(now.getTime() - millis)
  return startTime.toISOString()
}

function parseTime(time: string | null): string | undefined {
  if (!time) return undefined
  try {
    const date = new Date(time)
    return date.toISOString()
  } catch (e) {
    return undefined
  }
}

function timeRangeToMillis(timeRange: TimeRange): number | undefined {
  switch (timeRange) {
    case '30s':
      return 30 * 1000
    case '5m':
      return 5 * 60 * 1000
    case '15m':
      return 15 * 60 * 1000
    case '1h':
      return 60 * 60 * 1000
    case '6h':
      return 6 * 60 * 60 * 1000
    case '24h':
      return 24 * 60 * 60 * 1000
    case '3d':
      return 3 * 24 * 60 * 60 * 1000
    case '7d':
      return 7 * 24 * 60 * 60 * 1000
    case 'all':
      return undefined
  }
}
