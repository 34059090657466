import { AuthService } from './auth'
import { HTTPService } from './http'
import {
  LogFieldsResponse,
  LogOptionsRequest,
  LogOptionsResponse,
  LogQueryRequest,
  LogQueryResponse,
  ErrorQueryRequest,
  ErrorQueryResponse,
} from './types'

export class QueryService {
  private httpService: HTTPService
  private authService: AuthService

  constructor(httpService: HTTPService, authService: AuthService) {
    this.httpService = httpService
    this.authService = authService
  }

  logsQuery = async (request: LogQueryRequest): Promise<LogQueryResponse> => {
    return this.httpService.request<LogQueryResponse>(
      `/api/logs/query`,
      'POST',
      request,
      this.getAuthHeaders(),
    )
  }

  logsOptions = async (
    request: LogOptionsRequest,
  ): Promise<LogOptionsResponse> => {
    return this.httpService.request<LogOptionsResponse>(
      `/api/logs/options`,
      'POST',
      request,
      this.getAuthHeaders(),
    )
  }

  logsFields = async (request: LogQueryRequest): Promise<LogFieldsResponse> => {
    return this.httpService.request<LogFieldsResponse>(
      `/api/logs/fields`,
      'POST',
      request,
      this.getAuthHeaders(),
    )
  }

  errorsQuery = async (
    request: ErrorQueryRequest,
  ): Promise<ErrorQueryResponse> => {
    return this.httpService.request<ErrorQueryResponse>(
      `/api/errors/query`,
      'POST',
      request,
      this.getAuthHeaders(),
    )
  }

  private getAuthHeaders = (): Record<string, string> => {
    return { Authorization: `Bearer ${this.authService.getUserToken()}` }
  }
}
