import { useSetRecoilState } from 'recoil'
import {
  organizationIdState,
  organizationsState,
  projectIdState,
  projectsState,
} from '../../state/state'
import useAuthService from '../services/useAuthService'
import { useCallback } from 'react'
import useNav, { LinkKey } from './useNav'

const useLogout = () => {
  const auth = useAuthService()
  const navigate = useNav()

  const setOrganizationId = useSetRecoilState(organizationIdState)
  const setProjectId = useSetRecoilState(projectIdState)
  const setOrganizations = useSetRecoilState(organizationsState)
  const setProjects = useSetRecoilState(projectsState)

  return useCallback(() => {
    auth.clearUserToken()
    setOrganizationId(null)
    setProjectId(null)
    setOrganizations(null)
    setProjects(null)
    navigate(LinkKey.Login)
  }, [
    auth,
    navigate,
    setOrganizationId,
    setProjectId,
    setOrganizations,
    setProjects,
  ])
}

export default useLogout
