import { useRecoilValue } from 'recoil'
import {
  organizationMembersState,
  projectIdState,
  projectsState,
  userInfoState,
} from '../../../../../state/state'
import TableValue from '../../../../library/Table/TableValue'
import TableLabel from '../../../../library/Table/TableLabel'
import TableContainer from '../../../../library/Table/TableContainer'
import TableColumn from '../../../../library/Table/TableColumn'
import { OrganizationMember } from '../../../../../backend/types'
import { useMemo, useState } from 'react'
import Button from '../../../../library/Button/Button'
import PopupClose from '../../../../library/Popup/PopupClose'
import PopupHeader from '../../../../library/Popup/PopupHeader'
import Fullscreen from '../../../../library/Fullscreen/Fullscreen'
import PopupTitle from '../../../../library/Popup/PopupTitle'
import PopupBody from '../../../../library/Popup/PopupBody'
import { Pencil } from 'lucide-react'
import PopupContainer from '../../../../library/Popup/PopupContainer'
import { UserInfo } from '../../../../../state/types'
import Input from '../../../../library/Input/Input'
import useInitOrganizationMembers from '../../../../../hooks/data/effects/useInitOrganizationMemebers'
import useDeleteProject from '../../../../../hooks/actions/useDeleteProject'
import useUpdateProjectName from '../../../../../hooks/actions/useUpdateProjectName'
import useNav, { LinkKey } from '../../../../../hooks/utils/useNav'

const ProjectSettings = () => {
  useInitOrganizationMembers()

  const navigate = useNav()

  const deleteProject = useDeleteProject()
  const updateProjectName = useUpdateProjectName()

  const projectId = useRecoilValue(projectIdState)
  const projects = useRecoilValue(projectsState)
  const project = useMemo(
    () => projects?.find((p) => p.id === projectId),
    [projects, projectId],
  )

  const userInfo = useRecoilValue(userInfoState)
  const members = useRecoilValue(organizationMembersState)
  const isAdmin = useMemo(
    () => isUserAdmin(userInfo, members ?? []),
    [userInfo, members],
  )

  const [deletingProject, setDeletingProject] = useState(false)

  const [projectName, setProjectName] = useState(project?.name ?? '')
  const [editingProjectName, setEditingProjectName] = useState(false)
  const [nameConfirmation, setNameConfirmation] = useState('')

  const handleDeleteProject = async () => {
    const match = nameConfirmation === project?.name
    setDeletingProject(false)
    setNameConfirmation('')
    if (!project?.id || !match) return
    await deleteProject(project.id)
    navigate(LinkKey.Logs)
  }

  const handleSaveProjectName = async () => {
    const name = projectName.trim()
    setEditingProjectName(false)
    setProjectName('')
    if (!project?.id || !name) return
    await updateProjectName(projectName)
  }

  return (
    <div className="w-full flex flex-col items-start justify-start gap-8">
      <div className="text-[20px] font-medium text-text-1">Project Info</div>
      <TableContainer className="max-h-[400px] overflow-y-auto">
        <TableColumn className="min-w-[100px] flex-none">
          <TableLabel last={true}>Display Name</TableLabel>
        </TableColumn>
        <TableColumn className="flex-1">
          <TableValue last={true}>
            {project?.name}
            {isAdmin && (
              <Pencil
                className="w-4 h-4 cursor-pointer text-text-2 hover:text-text-1"
                onClick={() => setEditingProjectName(true)}
              />
            )}
          </TableValue>
        </TableColumn>
      </TableContainer>

      {isAdmin && (
        <div className="flex flex-col items-start justify-start gap-8">
          <div className="text-[20px] font-medium text-text-1">Danger Zone</div>
          {projects?.length === 1 && (
            <p className="text-[14px] text-text-2 leading-6">
              You cannot delete the only project in an organization.
            </p>
          )}
          <Button
            variant="destructive"
            disabled={projects?.length === 1}
            onClick={() => {
              if (projects?.length === 1) return
              setDeletingProject(true)
            }}
          >
            Delete Project
          </Button>
        </div>
      )}

      {editingProjectName && (
        <Fullscreen>
          <PopupContainer>
            <PopupHeader>
              <PopupTitle>Edit Project Name</PopupTitle>
              <PopupClose onClick={() => setEditingProjectName(false)} />
            </PopupHeader>
            <PopupBody className="gap-4">
              <Input
                className="text-[14px]"
                value={projectName}
                focusOnMount={true}
                placeholder={'New Name'}
                onChange={(e) => setProjectName(e.target.value)}
              />
              <Button onClick={handleSaveProjectName}>Save</Button>
            </PopupBody>
          </PopupContainer>
        </Fullscreen>
      )}

      {deletingProject && (
        <Fullscreen>
          <PopupContainer>
            <PopupHeader>
              <PopupTitle>Delete Project</PopupTitle>
              <PopupClose onClick={() => setDeletingProject(false)} />
            </PopupHeader>
            <PopupBody className="gap-4">
              <p className="text-[14px] text-destructive-base leading-6">
                Warning: This cannot be undone. All data will be deleted.
              </p>
              <p className="text-[14px] text-text-1 leading-6">
                Please type your project{' '}
                <span className="font-semibold">{project?.name}</span> to
                confirm.
              </p>
              <Input
                className="text-[14px]"
                value={nameConfirmation}
                focusOnMount={true}
                placeholder={'Type your project name to confirm'}
                onChange={(e) => setNameConfirmation(e.target.value)}
              />
              <div className="flex justify-end gap-4">
                <Button onClick={handleDeleteProject}>Delete</Button>
              </div>
            </PopupBody>
          </PopupContainer>
        </Fullscreen>
      )}
    </div>
  )
}

function isUserAdmin(userInfo: UserInfo | null, members: OrganizationMember[]) {
  return (
    members?.some((m) => m.user_id === userInfo?.id && m.role === 'admin') ||
    members?.some((m) => m.user_id === userInfo?.id && m.role === 'owner')
  )
}

export default ProjectSettings
