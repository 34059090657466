import { useRecoilValue } from 'recoil'
import Button from '../../../library/Button/Button'
import TableContainer from '../../../library/Table/TableContainer'
import TableLabel from '../../../library/Table/TableLabel'
import TableValue from '../../../library/Table/TableValue'
import { tokensState } from '../../../../state/state'
import TableColumn from '../../../library/Table/TableColumn'
import { useState } from 'react'
import Fullscreen from '../../../library/Fullscreen/Fullscreen'
import PopupContainer from '../../../library/Popup/PopupContainer'
import PopupHeader from '../../../library/Popup/PopupHeader'
import PopupTitle from '../../../library/Popup/PopupTitle'
import PopupClose from '../../../library/Popup/PopupClose'
import PopupBody from '../../../library/Popup/PopupBody'
import LabeledInput from '../../../library/LabeledInput/LabledInput'
import useCreateToken from '../../../../hooks/actions/useCreateToken'
import useDeleteToken from '../../../../hooks/actions/useDeleteToken'
import useInitTokens from '../../../../hooks/data/effects/useInitTokens'
import APITopbar from './APITopbar/APITopbar'
import CopyIcon from '../../../library/CopyIcon/CopyIcon'
import { Trash2 } from 'lucide-react'

const API: React.FC = () => {
  useInitTokens()

  const createToken = useCreateToken()
  const deleteToken = useDeleteToken()

  const tokens = useRecoilValue(tokensState)

  const [isCreating, setIsCreating] = useState(false)
  const [isDisplayingToken, setIsDisplayingToken] = useState(false)
  const [isDeletingToken, setIsDeletingToken] = useState(false)

  const [tokenName, setTokenName] = useState('')
  const [tokenDescription, setTokenDescription] = useState('')
  const [privateToken, setPrivateToken] = useState('')
  const [tokenToDelete, setTokenToDelete] = useState('')

  const handleCreateToken = async () => {
    const response = await createToken(tokenName, tokenDescription)
    if (!response) return
    setPrivateToken(response.token)
    setIsCreating(false)
    setIsDisplayingToken(true)
  }

  const handleDoneDisplayingToken = () => {
    setIsDisplayingToken(false)
    setIsCreating(false)
    setTokenName('')
    setTokenDescription('')
    setPrivateToken('')
  }

  const handleDeleteToken = async () => {
    await deleteToken(tokenToDelete)
    setIsDeletingToken(false)
    setTokenToDelete('')
  }

  return (
    <>
      <APITopbar />
      <div className="flex flex-col w-full max-w-[800px] p-8 overflow-hidden">
        <div className="w-full h-full flex flex-col items-start justify-start">
          <div className="text-[24px] font-semibold text-text-1 mb-4">
            Tokens
          </div>
          <p className="text-[14px] text-text-1 leading-6 mb-8">
            These are used to identify your project when sending data to
            Vigilant. Use them when creating an instance of the Vigilant logger
            in the your application.
          </p>
          <Button className="mb-8" onClick={() => setIsCreating(true)}>
            Create Token
          </Button>
          <TableContainer className="max-h-[400px] overflow-y-auto">
            <TableColumn className="min-w-[100px] flex-none">
              <TableLabel>Label</TableLabel>
              {(!tokens || tokens.length === 0) && (
                <TableValue className={'text-text-2'} last={true}>
                  No tokens yet
                </TableValue>
              )}
              {tokens?.map((token, index) => (
                <TableValue key={token.id} last={index === tokens.length - 1}>
                  {token.label}
                </TableValue>
              ))}
            </TableColumn>
            <TableColumn className="min-w-[100px] flex-none">
              <TableLabel>Token</TableLabel>
              {tokens?.map((token, index) => (
                <TableValue key={token.id} last={index === tokens.length - 1}>
                  {token.display_token}
                </TableValue>
              ))}
            </TableColumn>
            <TableColumn className="flex-1">
              <TableLabel>Description</TableLabel>
              {tokens?.map((token, index) => (
                <TableValue key={token.id} last={index === tokens.length - 1}>
                  {token.description}
                </TableValue>
              ))}
            </TableColumn>
            <TableColumn className="flex-1">
              <TableLabel>Created Time</TableLabel>
              {tokens?.map((token, index) => (
                <TableValue key={token.id} last={index === tokens.length - 1}>
                  {new Date(token.created_at).toLocaleString()}
                  <Trash2
                    className="w-4 h-4 cursor-pointer text-text-2 hover:text-text-1"
                    onClick={() => {
                      setTokenToDelete(token.id)
                      setIsDeletingToken(true)
                    }}
                  />
                </TableValue>
              ))}
            </TableColumn>
          </TableContainer>

          {isCreating && (
            <Fullscreen>
              <PopupContainer>
                <PopupHeader>
                  <PopupTitle>Create Token</PopupTitle>
                  <PopupClose onClick={() => setIsCreating(false)} />
                </PopupHeader>
                <PopupBody className="gap-4">
                  <LabeledInput
                    label={'Label'}
                    placeholder={'Example: Vercel Production'}
                    className={'w-full'}
                    value={tokenName}
                    onChange={(e) => setTokenName(e.target.value)}
                  />
                  <LabeledInput
                    label={'Description'}
                    placeholder={
                      'Example: This token is for Vercel in production'
                    }
                    className={'w-full'}
                    value={tokenDescription}
                    onChange={(e) => setTokenDescription(e.target.value)}
                  />
                  <Button onClick={handleCreateToken}>Create</Button>
                </PopupBody>
              </PopupContainer>
            </Fullscreen>
          )}
          {isDisplayingToken && (
            <Fullscreen>
              <PopupContainer>
                <PopupHeader>
                  <PopupTitle>Token Secret</PopupTitle>
                  <PopupClose onClick={handleDoneDisplayingToken} />
                </PopupHeader>
                <PopupBody className="gap-4">
                  <div className="text-[14px] text-text-1 leading-6">
                    Copy this token and use it when creating an instance of the
                    Vigilant logger in your application.
                  </div>
                  <div className="w-full px-4 py-3 pr-2 flex justify-between items-center border border-panel-border rounded-lg">
                    <div className="text-[14px] text-text-1">
                      {privateToken}
                    </div>
                    <CopyIcon
                      className="w-4 h-4 cursor-pointer text-text-2 hover:text-text-1"
                      onClick={() =>
                        navigator.clipboard.writeText(privateToken)
                      }
                    />
                  </div>
                  <p className="text-[14px] text-destructive-base leading-6">
                    <span className="font-semibold">Note:</span> This token will
                    only be shown once. If you lose it, you will have to create
                    a new one.
                  </p>
                  <Button onClick={handleDoneDisplayingToken}>Done</Button>
                </PopupBody>
              </PopupContainer>
            </Fullscreen>
          )}
          {isDeletingToken && (
            <Fullscreen>
              <PopupContainer>
                <PopupHeader>
                  <PopupTitle>Delete Token</PopupTitle>
                  <PopupClose onClick={() => setIsDeletingToken(false)} />
                </PopupHeader>
                <PopupBody className="gap-4">
                  <p className="text-[14px] text-text-1 leading-6">
                    Are you sure you want to delete{' '}
                    <span className="font-semibold">
                      {
                        tokens?.find((token) => token.id === tokenToDelete)
                          ?.label
                      }
                    </span>
                    ?
                  </p>
                  <p className="text-[14px] text-text-1 leading-6">
                    Warning: This cannot be undone.
                  </p>
                  <div className="flex justify-end gap-4">
                    <Button onClick={handleDeleteToken}>Delete</Button>
                  </div>
                </PopupBody>
              </PopupContainer>
            </Fullscreen>
          )}
        </div>
      </div>
    </>
  )
}

export default API
