import { projectIdState } from '../../state/state'
import { useRecoilValue } from 'recoil'
import useLoadProjects from '../data/load/useLoadProjects'
import useBackend from '../services/useBackend'

const useUpdateProjectName = () => {
  const backend = useBackend()

  const projectId = useRecoilValue(projectIdState)
  const loadProjects = useLoadProjects()

  const updateProjectName = async (name: string) => {
    if (!projectId) return
    await backend.updateProject(projectId, name)
    await loadProjects()
  }

  return updateProjectName
}

export default useUpdateProjectName
