interface IntegrationCardProps {
  name: string
  image: string
  onClick: () => void
}

const IntegrationCard: React.FC<IntegrationCardProps> = ({
  name,
  image,
  onClick,
}) => {
  return (
    <div
      className="w-full flex flex-col gap-4 items-center justify-center border border-panel-border rounded-md p-4 cursor-pointer transition-colors hover:border-cta-hover"
      onClick={onClick}
    >
      <img src={image} alt={name} className={'w-[148px] h-[148px]'} />
      <div className="w-full border-b border-panel-border"></div>
      <h3 className="text-[14px] text-text-1">{name}</h3>
    </div>
  )
}

export default IntegrationCard
