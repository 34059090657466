import { useState } from 'react'
import useNav, { LinkKey } from '../../../hooks/utils/useNav'
import OnboardingCard from '../../library/OnboardingCard/OnboardingCard'
import Button from '../../library/Button/Button'
import LabeledInput from '../../library/LabeledInput/LabledInput'
import useLoggedOut from '../../../hooks/utils/useLoggedOut'
import useCreateOrganization from '../../../hooks/actions/useCreateOrganization'
import useInitAdminData from '../../../hooks/data/effects/useInitAdminData'

const NewOrganization: React.FC = () => {
  const loggedOut = useLoggedOut()
  useInitAdminData()

  const navigate = useNav()
  const createOrganization = useCreateOrganization()

  const [name, setName] = useState('')

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    await createOrganization(name)
    navigate(LinkKey.Logs)
  }

  if (loggedOut) return <></>

  return (
    <div className="h-screen w-screen flex items-center justify-center bg-background">
      <OnboardingCard>
        <form onSubmit={handleSubmit} className="w-full flex flex-col gap-6">
          <div className="w-full flex flex-col items-start justify-center gap-1.5">
            <h2 className="text-[24px] font-semibold text-text-1 mb-2">
              Create a new organization
            </h2>
            <p className="text-[14px] text-text-2 leading-6">
              Use an organization to manage all logs at your company. You should
              share one organization between all projects and users.
            </p>
          </div>
          <div className="w-full flex flex-col items-start justify-center gap-4">
            <LabeledInput
              label={'Organization Name'}
              required={true}
              placeholder={'Enter your organization name'}
              value={name}
              type={'text'}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <Button type={'submit'} className={'w-full'}>
            Create
          </Button>
        </form>
      </OnboardingCard>
    </div>
  )
}

export default NewOrganization
