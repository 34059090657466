import { cn } from '../../../utils/cn'
import Prism from 'prismjs'
import 'prism-themes/themes/prism-coldark-dark.css'
import 'prismjs/components/prism-json'
import 'prismjs/components/prism-bash'
import 'prismjs/components/prism-go'
import 'prismjs/components/prism-typescript'
import 'prismjs/components/prism-python'
import { useEffect, useRef } from 'react'
import CopyIcon from '../CopyIcon/CopyIcon'

const CodeBlock = ({
  children,
  language = 'json',
  displayName,
  className,
}: {
  children: string
  displayName: string
  language?: string
  className?: string
}) => {
  const codeRef = useRef<HTMLElement>(null)

  useEffect(() => {
    if (codeRef.current) {
      Prism.highlightElement(codeRef.current)
    }
  }, [children, language])

  const copyToClipboard = () => {
    navigator.clipboard.writeText(children)
  }

  return (
    <div className="flex flex-col rounded-lg border border-panel-border">
      <div className="p-2 flex flex-row items-center justify-between border-b border-panel-border pb-2">
        <div className="text-[12px] text-text-2">{displayName}</div>
        <CopyIcon className="w-4 h-4" onClick={copyToClipboard} />
      </div>
      <pre className={cn('text-text-2 font-mono text-[12px]', className)}>
        <code ref={codeRef} className={`language-${language}`}>
          {children}
        </code>
      </pre>
    </div>
  )
}

export default CodeBlock
