import { ChevronUp } from 'lucide-react'
import { ErrorEntry } from '../../../../../backend/types'
import { useState } from 'react'

interface ErrorsContainerProps {
  errors: ErrorEntry[]
}

const UsedAttributes = [
  'filename',
  'line',
  'function',
  'service',
  'error',
  'stack',
]

const ErrorsContainer: React.FC<ErrorsContainerProps> = ({ errors }) => {
  const [open, setOpen] = useState<string[]>([])

  return (
    <div className="relative flex-1 flex flex-col gap-4 overflow-y-auto [&::-webkit-scrollbar]:hidden">
      {errors.map((error, i) => (
        <div
          key={error.id}
          className="relative w-full p-2 flex flex-col gap-2 font-mono text-[12px] text-text-1 border border-panel-border rounded-lg"
        >
          <ChevronUp
            className={`absolute right-2 top-2 w-4 h-4 text-text-2 cursor-pointer select-none ${
              open.includes(error.id) ? 'rotate-180' : ''
            }`}
            onClick={() => {
              setOpen(
                open.includes(error.id)
                  ? open.filter((id) => id !== error.id)
                  : [...open, error.id],
              )
            }}
          />
          <div className="flex font-semibold text-[12px]">
            {getService(error)}
          </div>
          <div className="flex gap-2">
            {getError(error)}
            <pre className="text-text-2">
              on {getFileNameLast(error)}:{getLine(error)} called from{' '}
              {getFunction(error)}
            </pre>
          </div>
          <div className="flex gap-2">
            <pre className="text-text-2">{getHowLongAgo(error)}</pre>
          </div>
          {open.includes(error.id) && (
            <>
              <div className="flex flex-col gap-1 my-2">
                <p className="text-text-2">trace</p>
                <pre className="text-text-2">{getStack(error)}</pre>
              </div>
              <div className="flex flex-col gap-1">
                <p className="text-text-2">metadata</p>
                {getAllAttributes(error).map(([key, value]) => (
                  <pre className="text-text-2">{`${key}: ${value}`}</pre>
                ))}
              </div>
            </>
          )}
        </div>
      ))}
      {errors.length === 0 && (
        <div className="p-2 text-text-1 text-[12px] font-mono">
          No errors found for the provided query
        </div>
      )}
    </div>
  )
}

function getFileNameLast(error: ErrorEntry) {
  if (!error.attributes['filename']) return null
  const filename = error.attributes['filename']
  return filename.split('/').pop()
}

function getLine(error: ErrorEntry) {
  if (!error.attributes['line']) return null
  const line = error.attributes['line']
  return line
}

function getFunction(error: ErrorEntry) {
  if (!error.attributes['function']) return null
  const functionName = error.attributes['function']
  return functionName
}

function getService(error: ErrorEntry) {
  if (!error.attributes['service']) return null
  const service = error.attributes['service']
  return service
}

function getError(error: ErrorEntry) {
  if (!error.error) return null
  const errorMessage = error.error
  return errorMessage
}

function getStack(error: ErrorEntry) {
  if (!error.attributes['stack']) return null
  const stack = error.attributes['stack']
  return stack
}

function getAllAttributes(error: ErrorEntry): [string, string][] {
  return Object.entries(error.attributes).filter(
    ([key]) => !UsedAttributes.includes(key),
  )
}

export default ErrorsContainer

function getHowLongAgo(error: ErrorEntry) {
  if (!error.timestamp) return null
  const timestamp = error.timestamp

  try {
    const date = new Date(timestamp)
    const now = new Date()
    const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000)

    if (diffInSeconds < 60) {
      return 'just now'
    } else if (diffInSeconds < 3600) {
      const value = Math.floor(diffInSeconds / 60)
      if (value === 1) return '1 minute ago'
      return `${value} minutes ago`
    } else if (diffInSeconds < 86400) {
      const value = Math.floor(diffInSeconds / 3600)
      if (value === 1) return '1 hour ago'
      return `${value} hours ago`
    } else if (diffInSeconds < 86400) {
      const value = Math.floor(diffInSeconds / 86400)
      if (value === 1) return 'yesterday'
      return `${value} days ago`
    } else if (diffInSeconds < 2592000) {
      const value = Math.floor(diffInSeconds / 2592000)
      if (value === 1) return 'last month'
      return `${value} months ago`
    }
    const value = Math.floor(diffInSeconds / 31536000)
    if (value === 1) return 'last year'
    return `${value} years ago`
  } catch (e) {
    return timestamp
  }
}
