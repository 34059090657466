import { useRecoilValue } from 'recoil'
import { projectIdState } from '../../state/state'
import useBackend from '../services/useBackend'
import useLoadTokens from '../data/load/useLoadTokens'

const useCreateToken = () => {
  const backend = useBackend()
  const projectId = useRecoilValue(projectIdState)
  const loadTokens = useLoadTokens()

  const createToken = async (label: string, description: string) => {
    if (!projectId) return
    try {
      const key = await backend.createProjectToken(
        projectId,
        label,
        description,
      )
      await loadTokens()
      return key
    } catch (e) {}
  }

  return createToken
}

export default useCreateToken
