import { X } from 'lucide-react'
import { LogEntry } from '../../../../../state/types'
import React from 'react'

interface LogDetailsProps {
  log: LogEntry
  close: () => void
}

const LogDetails: React.FC<LogDetailsProps> = ({ log, close }) => {
  const formatValue = (value: any) => {
    if (typeof value === 'object') {
      return (
        <pre className="overflow-auto">{JSON.stringify(value, null, 2)}</pre>
      )
    }
    return <pre className="overflow-auto">{String(value)}</pre>
  }

  return (
    <div className="w-[400px] flex-none flex flex-col gap-2 text-[12px] text-text-1 border border-panel-border rounded-lg overflow-auto p-4">
      <div className="flex flex-row justify-between">
        <h2 className="text-text-1 font-bold mb-2">Log Details</h2>
        <X className="w-[14px] h-[14px] cursor-pointer" onClick={close} />
      </div>
      {Object.entries(log).map(([key, value]) => (
        <React.Fragment key={key}>
          {value && (
            <div className="flex flex-col gap-1">
              <div className="font-medium text-text-2 capitalize">
                {key.replace(/_/g, ' ')}:
              </div>
              <div className="pl-2 font-mono">{formatValue(value)}</div>
            </div>
          )}
        </React.Fragment>
      ))}

      <div className="mt-4 pt-4 border-t border-panel-border">
        <div className="font-medium text-text-2">Raw Log:</div>
        <pre className="mt-2 p-2 bg-panel-border rounded font-mono overflow-auto">
          {JSON.stringify(log, null, 2)}
        </pre>
      </div>
    </div>
  )
}

export default LogDetails
