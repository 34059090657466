import { useEffect } from 'react'
import useLoadOrganizationMembers from '../load/useLoadOrganizationMembers'

const useInitOrganizationMembers = () => {
  const loadOrganizationMembers = useLoadOrganizationMembers()

  useEffect(() => {
    loadOrganizationMembers()
  }, [loadOrganizationMembers])
}

export default useInitOrganizationMembers
