import { useRecoilValue } from 'recoil'
import {
  organizationInfoState,
  organizationMembersState,
  userInfoState,
} from '../../../../../state/state'
import TableValue from '../../../../library/Table/TableValue'
import TableLabel from '../../../../library/Table/TableLabel'
import TableContainer from '../../../../library/Table/TableContainer'
import TableColumn from '../../../../library/Table/TableColumn'
import useInitOrganizationMembers from '../../../../../hooks/data/effects/useInitOrganizationMemebers'
import {
  OrganizationMember,
  OrganizationRole,
} from '../../../../../backend/types'
import { useMemo, useState } from 'react'
import useCreateOrganizationInvite from '../../../../../hooks/actions/useCreateOrganizationInvite'
import Button from '../../../../library/Button/Button'
import PopupClose from '../../../../library/Popup/PopupClose'
import PopupHeader from '../../../../library/Popup/PopupHeader'
import Fullscreen from '../../../../library/Fullscreen/Fullscreen'
import PopupTitle from '../../../../library/Popup/PopupTitle'
import PopupBody from '../../../../library/Popup/PopupBody'
import { Pencil, Trash2 } from 'lucide-react'
import PopupContainer from '../../../../library/Popup/PopupContainer'
import useDeleteOrganizationMember from '../../../../../hooks/actions/useDeleteOrganizationMember'
import { UserInfo } from '../../../../../state/types'
import Input from '../../../../library/Input/Input'
import { useUpdateOrganizationName } from '../../../../../hooks/actions/useUpdateOrganizationName'
import { useDeleteOrganization } from '../../../../../hooks/actions/useDeleteOrganization'
import LabeledInput from '../../../../library/LabeledInput/LabledInput'

const OrganizationSettings = () => {
  useInitOrganizationMembers()

  const updateOrganizationName = useUpdateOrganizationName()
  const deleteOrganization = useDeleteOrganization()
  const createInvite = useCreateOrganizationInvite()
  const deleteMember = useDeleteOrganizationMember()

  const organizationInfo = useRecoilValue(organizationInfoState)
  const userInfo = useRecoilValue(userInfoState)
  const members = useRecoilValue(organizationMembersState)
  const isAdmin = useMemo(
    () => isUserAdmin(userInfo, members ?? []),
    [userInfo, members],
  )

  const [deletingMember, setDeletingMember] = useState<string | null>(null)
  const [deletingOrganization, setDeletingOrganization] = useState(false)
  const [invitingMember, setInvitingMember] = useState(false)

  const [orgName, setOrgName] = useState(organizationInfo?.name ?? '')
  const [editingOrgName, setEditingOrgName] = useState(false)
  const [nameConfirmation, setNameConfirmation] = useState('')
  const [inviteeEmail, setInviteeEmail] = useState('')

  const handleCreateInvite = async () => {
    if (!organizationInfo?.id) return
    await createInvite(inviteeEmail)
    setInviteeEmail('')
    setInvitingMember(false)
  }

  const handleDeleteMember = async (id: string) => {
    if (!organizationInfo?.id) return
    await deleteMember(organizationInfo.id, id)
    setDeletingMember(null)
  }

  const handleSaveOrgName = async () => {
    if (!organizationInfo?.id) return
    await updateOrganizationName(orgName)
    setEditingOrgName(false)
    setOrgName(organizationInfo.name)
  }

  const handleDeleteOrganization = async () => {
    if (!organizationInfo?.id) return
    if (nameConfirmation !== organizationInfo.name) return
    await deleteOrganization(organizationInfo.id)
    setDeletingOrganization(false)
  }

  return (
    <div className="w-full flex flex-col items-start justify-start gap-8">
      <div className="text-[20px] font-medium text-text-1">
        Organization Info
      </div>
      <TableContainer className="max-h-[400px] overflow-y-auto">
        <TableColumn className="min-w-[100px] flex-none">
          <TableLabel last={true}>Display Name</TableLabel>
        </TableColumn>
        <TableColumn className="flex-1">
          <TableValue last={true}>
            {organizationInfo?.name}
            {isAdmin && (
              <Pencil
                className="w-4 h-4 cursor-pointer text-text-2 hover:text-text-1"
                onClick={() => setEditingOrgName(true)}
              />
            )}
          </TableValue>
        </TableColumn>
      </TableContainer>

      <div className="text-[20px] font-medium text-text-1">Members</div>
      <TableContainer className="max-h-[400px] overflow-y-auto">
        <TableColumn className="min-w-[100px] flex-none">
          <TableLabel>Email</TableLabel>
          {members?.map((m, i) => (
            <TableValue key={m.user_id} last={i === members.length - 1}>
              {m.email}
            </TableValue>
          ))}
        </TableColumn>
        <TableColumn className="flex-1">
          <TableLabel>Role</TableLabel>
          {members?.map((m, i) => (
            <TableValue key={m.user_id} last={i === members.length - 1}>
              {formatRole(m.role)}
              {isAdmin && m.role !== 'owner' && (
                <div className="flex items-center">
                  <Trash2
                    className="w-4 h-4 cursor-pointer text-text-2 hover:text-text-1"
                    onClick={() => setDeletingMember(m.user_id)}
                  />
                </div>
              )}
            </TableValue>
          ))}
        </TableColumn>
      </TableContainer>
      <Button onClick={() => setInvitingMember(true)}>Invite Member</Button>

      {isAdmin && (
        <div className="flex flex-col items-start justify-start gap-8">
          <div className="text-[20px] font-medium text-text-1">Danger Zone</div>
          <Button
            variant="destructive"
            onClick={() => setDeletingOrganization(true)}
          >
            Delete Organization
          </Button>
        </div>
      )}

      {invitingMember && (
        <Fullscreen>
          <PopupContainer>
            <PopupHeader>
              <PopupTitle>Invite Teammate</PopupTitle>
              <PopupClose onClick={() => setInvitingMember(false)} />
            </PopupHeader>
            <PopupBody className="gap-4">
              <p className="text-[14px] text-text-1 leading-6">
                Enter the email of the person you want to invite to your
                organization. They will receive an email with a link to join the
                organization.
              </p>
              <LabeledInput
                label="Teammate Email"
                value={inviteeEmail}
                placeholder={'email@yourcompany.com'}
                onChange={(e) => setInviteeEmail(e.target.value)}
              />
              <Button onClick={handleCreateInvite}>Done</Button>
            </PopupBody>
          </PopupContainer>
        </Fullscreen>
      )}

      {deletingMember && (
        <Fullscreen>
          <PopupContainer>
            <PopupHeader>
              <PopupTitle>Delete Member</PopupTitle>
              <PopupClose onClick={() => setDeletingMember(null)} />
            </PopupHeader>
            <PopupBody className="gap-4">
              <p className="text-[14px] text-text-1 leading-6">
                Are you sure you want to delete{' '}
                <span className="font-semibold">
                  {members?.find((m) => m.user_id === deletingMember)?.email}
                </span>
                ?
              </p>
              <p className="text-[14px] text-destructive-base leading-6">
                Warning: This cannot be undone.
              </p>
              <div className="flex justify-end gap-4">
                <Button onClick={() => handleDeleteMember(deletingMember)}>
                  Delete
                </Button>
              </div>
            </PopupBody>
          </PopupContainer>
        </Fullscreen>
      )}

      {editingOrgName && (
        <Fullscreen>
          <PopupContainer>
            <PopupHeader>
              <PopupTitle>Edit Organization Name</PopupTitle>
              <PopupClose onClick={() => setEditingOrgName(false)} />
            </PopupHeader>
            <PopupBody className="gap-4">
              <Input
                value={orgName}
                focusOnMount={true}
                placeholder={'New Name'}
                onChange={(e) => setOrgName(e.target.value)}
              />
              <Button onClick={handleSaveOrgName}>Save</Button>
            </PopupBody>
          </PopupContainer>
        </Fullscreen>
      )}

      {deletingOrganization && (
        <Fullscreen>
          <PopupContainer>
            <PopupHeader>
              <PopupTitle>Delete Organization</PopupTitle>
              <PopupClose onClick={() => setDeletingOrganization(false)} />
            </PopupHeader>
            <PopupBody className="gap-4">
              <p className="text-[14px] text-destructive-base leading-6">
                Warning: This cannot be undone. All data will be deleted.
              </p>
              <p className="text-[14px] text-text-1 leading-6">
                Please type your organization{' '}
                <span className="font-semibold">{organizationInfo?.name}</span>{' '}
                to confirm.
              </p>
              <Input
                className="text-[14px]"
                value={nameConfirmation}
                focusOnMount={true}
                placeholder={'Type your organization name to confirm'}
                onChange={(e) => setNameConfirmation(e.target.value)}
              />
              <div className="flex justify-end gap-4">
                <Button onClick={handleDeleteOrganization}>Delete</Button>
              </div>
            </PopupBody>
          </PopupContainer>
        </Fullscreen>
      )}
    </div>
  )
}

function formatRole(role: OrganizationRole) {
  switch (role) {
    case 'owner':
      return 'Owner'
    case 'admin':
      return 'Admin'
    case 'user':
      return 'Member'
  }
}

function isUserAdmin(userInfo: UserInfo | null, members: OrganizationMember[]) {
  return (
    members?.some((m) => m.user_id === userInfo?.id && m.role === 'admin') ||
    members?.some((m) => m.user_id === userInfo?.id && m.role === 'owner')
  )
}

export default OrganizationSettings
