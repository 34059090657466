import { useRecoilValue, useSetRecoilState } from 'recoil'
import { organizationIdState, projectIdState } from '../../state/state'
import useBackend from '../services/useBackend'

const useSwitchProject = () => {
  const backend = useBackend()

  const organizationId = useRecoilValue(organizationIdState)
  const setProjectId = useSetRecoilState(projectIdState)

  const switchProject = async (projectId: string) => {
    if (!organizationId) return
    try {
      await backend.authProject(organizationId, projectId)
      setProjectId(projectId)
    } catch (error) {}
  }

  return switchProject
}

export default useSwitchProject
