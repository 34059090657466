import posthog from 'posthog-js'
import { useRecoilValue } from 'recoil'
import { userInfoState } from '../../state/state'
import { useEffect } from 'react'

const usePosthogIdentify = () => {
  const userInfo = useRecoilValue(userInfoState)

  useEffect(() => {
    if (userInfo.id === '' || userInfo.email === '') return
    posthog.identify(userInfo.id, { email: userInfo.email })
  }, [userInfo])
}

export { usePosthogIdentify }
