import { cn } from '../../../utils/cn'

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'cta' | 'outline' | 'destructive'
  disabled?: boolean
  selected?: boolean
}

const Button: React.FC<ButtonProps> = ({
  children,
  variant = 'cta',
  selected = false,
  disabled = false,
  className = '',
  ...props
}) => {
  const baseClasses =
    'flex items-center justify-center gap-2 px-4 py-2 rounded-md text-[14px] whitespace-nowrap'

  const variantClasses = {
    cta: `bg-cta-base ${
      disabled ? 'opacity-50 cursor-default' : 'hover:bg-cta-hover'
    } text-cta-text ${selected ? 'bg-cta-hover' : ''}`,
    destructive: `bg-destructive-base ${
      disabled ? 'opacity-50 cursor-default' : 'hover:bg-destructive-hover'
    } text-cta-text ${selected ? 'bg-destructive-hover' : ''}`,
    outline: `border border-panel-border ${
      disabled ? 'opacity-50 cursor-default' : 'hover:bg-input-active'
    } text-text-1 ${selected ? 'bg-input-active' : ''}`,
  }

  return (
    <button
      className={cn(baseClasses, variantClasses[variant], className)}
      {...props}
    >
      {children}
    </button>
  )
}

export default Button
