import { useState } from 'react'
import Button from '../../../../../library/Button/Button'
import useCreateToken from '../../../../../../hooks/actions/useCreateToken'
import Step from '../../../../../library/Step/Step'
import CodeBlock from '../../../../../library/CodeBlock/CodeBlock'

interface GoDocsProps {
  close: () => void
}

const url = process.env.REACT_APP_LOG_ADDRESS || 'log.vigilant.run:4317'

const GoDocs: React.FC<GoDocsProps> = ({ close }) => {
  const createToken = useCreateToken()
  const [token, setToken] = useState('')

  const handleCreateToken = async () => {
    const token = await createToken('Go SDK Token', 'Token for Go SDK.')
    if (token) setToken(token.token)
  }

  return (
    <div className="flex flex-col w-full max-w-[800px] p-8">
      <div className="w-full h-full flex flex-col items-start justify-start">
        <div className="w-full flex flex-row items-center justify-between mb-4">
          <div className="text-[24px] font-semibold text-text-1">Go Setup</div>
          <Button variant="outline" onClick={close}>
            Back
          </Button>
        </div>
        <p className="text-[14px] text-text-1 leading-6 mb-8">
          Follow these instructions to setup the Vigilant Go SDK.
        </p>

        <Step number={1} title="Install the SDK">
          <p className="text-[14px] text-text-1 leading-6">
            Install the SDK using the following command:
          </p>
          <CodeBlock
            displayName={'Bash'}
            language={'bash'}
            children={'go get github.com/vigilant-run/vigilant-golang'}
          />
        </Step>

        <Step number={2} title="Create a Token">
          <p className="text-[14px] text-text-1 leading-6">
            Create a secret token to authenticate the SDK with Vigilant.
          </p>
          <div className="flex flex-col items-start justify-start">
            <Button
              disabled={!!token}
              variant="cta"
              onClick={handleCreateToken}
            >
              Create Token
            </Button>
          </div>
        </Step>

        <Step number={3} title="Use the SDK" disabled={!token}>
          <p className="text-[14px] text-text-1 leading-6">
            Use the SDK to send logs to Vigilant.
          </p>
          <CodeBlock
            displayName={'Go'}
            language={'go'}
            children={getCodeSnippet(url, token)}
          />
        </Step>

        <Step number={4} title="Run your application" disabled={!token}>
          <p className="text-[14px] text-text-1 leading-6">
            Run your application and you should start seeing logs in Vigilant.
          </p>
        </Step>

        <div className="w-full h-[100px]"></div>
      </div>
    </div>
  )
}

export default GoDocs

function getCodeSnippet(url: string, token: string) {
  return `package main

import (
  "context"
  "github.com/vigilant-run/vigilant-golang"
)

func main() {
  loggerOptions := vigilant.NewLoggerOptions(
    vigilant.WithLoggerURL("${url}"),
    vigilant.WithLoggerToken("${token}"),
    vigilant.WithLoggerName("sample-app"),
  )

  logger := vigilant.NewLogger(loggerOptions)
  logger.Info(context.Background(), "Hello, World!")
}
`
}
