import { useCallback } from 'react'
import useQuery from '../../services/useQuery'
import { useRecoilValue } from 'recoil'
import { projectIdState } from '../../../state/state'
import { Filter } from '../../../backend/types'

const useLoadFields = () => {
  const query = useQuery()

  const projectId = useRecoilValue(projectIdState)

  const loadFields = useCallback(
    async (
      term: string,
      startTime?: string,
      endTime?: string,
      filters: Filter[] = [],
    ) => {
      if (!projectId) return { total: 0, attributes: {}, resources: {} }
      const fields = await query.logsFields({
        project_id: projectId,
        search_term: term,
        start_timestamp: startTime,
        end_timestamp: endTime,
        filters: filters,
      })
      return fields
    },
    [query, projectId],
  )

  return loadFields
}

export default useLoadFields
