import { useRecoilValue } from 'recoil'
import useBackend from '../services/useBackend'
import { organizationsState } from '../../state/state'
import useLoadOrganizations from '../data/load/useLoadOrganizations'
import useSwitchOrganization from './useSwitchOrganization'
import useNav, { LinkKey } from '../utils/useNav'

export const useDeleteOrganization = () => {
  const backend = useBackend()
  const navigate = useNav()

  const organizations = useRecoilValue(organizationsState)
  const switchOrganization = useSwitchOrganization()
  const loadOrganizations = useLoadOrganizations()

  return async (id: string) => {
    if (!organizations?.find((o) => o.id === id)) return
    await backend.deleteOrganization(id)
    await loadOrganizations()
    const newOrganization = organizations.find((o) => o.id !== id)
    if (newOrganization) {
      await switchOrganization(newOrganization.id)
      navigate(LinkKey.Logs)
    } else {
      navigate(LinkKey.NewOrganization)
    }
  }
}
