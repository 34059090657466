import useBackend from '../services/useBackend'
import useSelectOrganization from './useSelectOrganization'
import useSelectProject from './useSelectProject'

const useSwitchOrganization = () => {
  const backend = useBackend()

  const selectOrganization = useSelectOrganization()
  const selectProject = useSelectProject()

  const switchOrganization = async (organizationId: string) => {
    if (!organizationId) return
    try {
      await selectOrganization(organizationId)

      const { projects } = await backend.getProjects(organizationId)
      if (projects.length === 0) return

      const projectId = projects[0].id
      await selectProject(organizationId, projectId)
    } catch (error) {}
  }

  return switchOrganization
}

export default useSwitchOrganization
