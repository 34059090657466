import { Search } from 'lucide-react'
import { useState, useRef } from 'react'
import IntegrationCard from './IntegrationCard/IntegrationCard'
import RequestCard from './RequestCard/RequestCard'
import Docs, { Integration } from './Docs/Docs'
import IntegrationsTopbar from './IntegrationsTopbar/IntegrationsTopbar'

const Integrations: React.FC = () => {
  const searchInputRef = useRef<HTMLInputElement>(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [selected, setSelected] = useState<Integration | null>(null)

  return (
    <>
      <IntegrationsTopbar />
      {selected ? (
        <Docs name={selected} close={() => setSelected(null)} />
      ) : (
        <div className="flex flex-col w-full max-w-[800px] p-8 overflow-hidden">
          <div className="w-full h-full flex flex-col items-start justify-start">
            <div className="text-[24px] font-semibold text-text-1 mb-4">
              Integrations
            </div>
            <p className="text-[14px] text-text-1 leading-6 mb-4">
              Choose the language you use to build your app.
            </p>
            <div className="w-full flex flex-row gap-2 items-center border border-panel-border rounded-md p-2 mb-4">
              <Search className="w-4 h-4 text-text-1" />
              <input
                ref={searchInputRef}
                type="text"
                className="w-full text-text-1 text-[14px] placeholder:text-text-2"
                placeholder="Search languages (e.g. 'go')"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className="w-full grid grid-cols-[repeat(auto-fill,minmax(200px,1fr))] gap-4">
              {integrations
                .filter((i) =>
                  i.name.toLowerCase().includes(searchTerm.toLowerCase()),
                )
                .map((i) => (
                  <IntegrationCard
                    key={i.name}
                    name={i.name}
                    image={i.image}
                    onClick={() => setSelected(i.type)}
                  />
                ))}
              <RequestCard />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const integrations: {
  name: string
  type: Integration
  image: string
}[] = [
  {
    name: 'Node.js',
    type: 'node',
    image: '/integrations/nodejs.png',
  },
  {
    name: 'Python',
    type: 'python',
    image: '/integrations/python.png',
  },
  {
    name: 'Go',
    type: 'go',
    image: '/integrations/go.svg',
  },
]

export default Integrations
