import { useSetRecoilState } from 'recoil'
import useBackend from '../services/useBackend'
import { organizationIdState } from '../../state/state'

const useSelectOrganization = () => {
  const backend = useBackend()
  const setOrganizationId = useSetRecoilState(organizationIdState)

  const switchOrganization = async (organizationId: string) => {
    await backend.authOrganization(organizationId)
    setOrganizationId(organizationId)
  }

  return switchOrganization
}

export default useSelectOrganization
