import useNav, { LinkKey } from '../../../../hooks/utils/useNav'
import Button from '../../../library/Button/Button'
import SettingsTopbar from './SettingsTopbar/SettingsTopbar'
import { Outlet, useLocation } from 'react-router-dom'

type SettingsTab = 'organization' | 'project' | 'account'

const tabs: SettingsTab[] = ['organization', 'project', 'account']

const Settings: React.FC = () => {
  const navigate = useNav()
  const location = useLocation()

  return (
    <>
      <SettingsTopbar />
      <div className="flex flex-col w-full max-w-[800px] p-8 overflow-hidden">
        <div className="w-full h-full flex flex-col items-start justify-start">
          <div className="w-full flex flex-row items-center justify-start gap-4 mb-8">
            {tabs.map((t) => (
              <Button
                key={t}
                variant={'outline'}
                selected={location.pathname.includes(t)}
                onClick={() => {
                  switch (t) {
                    case 'account':
                      navigate(LinkKey.AccountSettings)
                      break
                    case 'organization':
                      navigate(LinkKey.OrganizationSettings)
                      break
                    case 'project':
                      navigate(LinkKey.ProjectSettings)
                      break
                  }
                }}
              >
                {formatTab(t)}
              </Button>
            ))}
          </div>
          <Outlet />
        </div>
      </div>
    </>
  )
}

function formatTab(tab: SettingsTab) {
  switch (tab) {
    case 'account':
      return 'Account'
    case 'organization':
      return 'Organization'
    case 'project':
      return 'Project'
  }
}

export default Settings
