import { cn } from '../../../utils/cn'

interface TableColumnProps {
  children: React.ReactNode
  className?: string
}

const TableColumn: React.FC<TableColumnProps> = ({ children, className }) => {
  return <div className={cn('flex flex-col', className)}>{children}</div>
}

export default TableColumn
