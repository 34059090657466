import Topbar from '../../../../library/Topbar/Topbar'
import TopbarDivider from '../../../../library/TopbarDivider/TopbarDivider'
import TopbarOrganization from '../../../../library/TopbarOrganization/TopbarOrganization'
import TopbarProject from '../../../../library/TopbarProject/TopbarProject'

const ErrorsTopbar: React.FC = () => {
  return (
    <Topbar>
      <TopbarOrganization />
      <TopbarDivider />
      <TopbarProject />
      <TopbarDivider />
      <div className="text-[12px] font-semibold text-text-1">Errors</div>
    </Topbar>
  )
}

export default ErrorsTopbar
