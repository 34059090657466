interface StepNumberProps {
  number: number
}

const StepNumber: React.FC<StepNumberProps> = ({ number }) => {
  return (
    <div className="h-[32px] w-[32px] flex-none flex items-center justify-center text-[14px] font-semibold text-text-1 border border-panel-border rounded-md">
      {number}
    </div>
  )
}

export default StepNumber
