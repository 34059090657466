import posthog from 'posthog-js'
import { useEffect } from 'react'

export const usePosthogInit = () => {
  useEffect(() => {
    posthog.init('phc_tsAMGLw5Cu4gYofOhFIfSk8tUFr7B3tBeMrhv0i6uXW', {
      api_host: 'https://us.i.posthog.com',
      person_profiles: 'identified_only',
    })
  }, [])
}
