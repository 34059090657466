import { Check, Copy } from 'lucide-react'
import { cn } from '../../../utils/cn'
import { useState, useRef } from 'react'

interface CopyIconProps {
  className?: string
  onClick?: () => void
}

const CopyIcon = ({ className, onClick }: CopyIconProps) => {
  const [showCheck, setShowCheck] = useState(false)
  const timeoutId = useRef<NodeJS.Timeout | null>(null)

  if (showCheck) {
    return (
      <Check className={cn('w-4 h-4 cursor-pointer text-text-1', className)} />
    )
  } else {
    return (
      <Copy
        className={cn(
          'w-4 h-4 cursor-pointer text-text-2 hover:text-text-1',
          className,
        )}
        onClick={() => {
          setShowCheck(true)
          if (timeoutId.current) {
            clearTimeout(timeoutId.current)
          }
          timeoutId.current = setTimeout(() => setShowCheck(false), 2000)
          onClick?.()
        }}
      />
    )
  }
}

export default CopyIcon
