import { useRef } from 'react'
import { Building2, Plus } from 'lucide-react'
import { OrganizationInfo } from '../../../../state/types'
import useClickOutside from '../../../../hooks/utils/useClickOutside'
import useNav, { LinkKey } from '../../../../hooks/utils/useNav'
import IconButton from '../../IconButton/IconButton'

interface OrganizationPopupProps {
  organizationId: string
  organizations: OrganizationInfo[]
  selectOrganization: (id: string) => Promise<void>

  open: boolean
  close: () => void
  sourceRef: React.RefObject<HTMLDivElement>
}

const OrganizationPopup: React.FC<OrganizationPopupProps> = ({
  organizationId,
  organizations,
  selectOrganization,
  open,
  close,
  sourceRef,
}) => {
  const navigate = useNav()

  const ref = useRef<HTMLDivElement>(null)
  useClickOutside(ref, close, sourceRef)

  const handleSelect = async (id: string) => {
    await selectOrganization(id)
    close()
  }

  const handleCreate = () => {
    navigate(LinkKey.NewOrganization)
    close()
  }

  if (!open) return <></>

  return (
    <div
      className="absolute left-0 top-full mt-2 p-3 flex-0 flex flex-col gap-1 bg-background border border-panel-border rounded-[6px] shadow-offset-md shadow-popupShadow z-[10]"
      ref={ref}
    >
      <p className="text-[12px] text-text-2 py-2 mb-2">Organizations</p>
      <div className="border-b border-panel-border mb-2" />
      {organizations?.map((organization) => (
        <IconButton
          key={organization.id}
          text={organization.name}
          active={organization?.id === organizationId}
          icon={<Building2 size={16} className={'text-text-1'} />}
          onClick={() => handleSelect(organization.id)}
        />
      ))}
      <IconButton
        text="Create Organization"
        icon={<Plus size={16} className={'text-text-1'} />}
        onClick={handleCreate}
      />
    </div>
  )
}

export default OrganizationPopup
