import { useRef } from 'react'
import { PanelsTopLeft, Plus } from 'lucide-react'
import useClickOutside from '../../../../hooks/utils/useClickOutside'
import IconButton from '../../IconButton/IconButton'
import { ProjectInfo } from '../../../../state/types'
import useNav, { LinkKey } from '../../../../hooks/utils/useNav'

interface ProjectPopupProps {
  projectId: string
  projects: ProjectInfo[]
  setProject: (id: string) => Promise<void>

  open: boolean
  close: () => void
  sourceRef: React.RefObject<HTMLDivElement>
}

const ProjectPopup: React.FC<ProjectPopupProps> = ({
  projectId,
  projects,
  setProject,
  open,
  close,
  sourceRef,
}) => {
  const navigate = useNav()

  const ref = useRef<HTMLDivElement>(null)
  useClickOutside(ref, close, sourceRef)

  const handleSelect = async (id: string) => {
    await setProject(id)
    close()
  }

  if (!open) return <></>

  return (
    <div
      className="absolute left-0 top-full mt-2 p-3 flex-0 flex flex-col gap-1 bg-background border border-panel-border rounded-[6px] shadow-offset-md shadow-popupShadow z-[10]"
      ref={ref}
    >
      <p className="text-[12px] text-text-2 py-2 mb-2">Projects</p>
      <div className="border-b border-panel-border mb-2" />
      {projects?.map((project) => (
        <IconButton
          key={project.id}
          text={project.name}
          active={project?.id === projectId}
          icon={<PanelsTopLeft size={16} className={'text-text-1'} />}
          onClick={() => handleSelect(project.id)}
        />
      ))}
      <IconButton
        text={'New Project'}
        icon={<Plus size={16} className={'text-text-1'} />}
        onClick={() => navigate(LinkKey.NewProject)}
      />
    </div>
  )
}

export default ProjectPopup
