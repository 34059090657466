import { useCallback, useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { projectIdState } from '../../../../state/state'
import { LogEntry } from '../../../../state/types'
import LogSearchBar from './LogSearchBar/LogSearchBar'
import LogContainer from './LogContainer/LogContainer'
import LogDetails from './LogDetails/LogDetails'
import useLoadLogs from '../../../../hooks/data/load/useLoadLogs'
import { LogFieldsResponse, Filter } from '../../../../backend/types'
import LogsTopbar from './LogsTopbar/LogsTopbar'
import useLoadFields from '../../../../hooks/data/load/useLoadFields'
import LogFields from './LogAttributes/LogFields'
import { getTimestamps, TimeRange } from '../../../../utils/time'

export type Column = {
  name: string
  width: number
}

const Logs: React.FC = () => {
  const loadLogs = useLoadLogs()
  const loadFields = useLoadFields()
  const projectId = useRecoilValue(projectIdState)

  const [logs, setLogs] = useState<LogEntry[]>([])
  const [fields, setFields] = useState<LogFieldsResponse>({
    total: 0,
    attributes: {},
    resources: {},
  })
  const [columns, setColumns] = useState<Column[]>([
    { name: 'timestamp', width: 180 },
    { name: 'level', width: 100 },
    { name: 'resource.service.name', width: 180 },
    { name: 'body', width: 1000 },
  ])

  const [selected, setSelected] = useState<LogEntry | undefined>(undefined)

  const [searchTerm, setSearchTerm] = useState('')
  const [timeRange, setTimeRange] = useState<TimeRange>('all')
  const [live, setLive] = useState(true)
  const [filters, setFilters] = useState<Filter[]>([])

  const refreshLogs = useCallback(
    async (
      searchTerm: string,
      timeRange: TimeRange,
      filters: Filter[],
      limit: number = 100,
      offset: number = 0,
    ) => {
      if (!projectId) return
      const [start, end] = getTimestamps(timeRange)
      const logs = await loadLogs(
        searchTerm,
        start,
        end,
        filters,
        limit,
        offset,
      )
      if (logs) {
        setLogs(logs)
      }
    },
    [projectId, loadLogs],
  )

  const refreshFields = useCallback(
    async (searchTerm: string, timeRange: TimeRange, filters: Filter[]) => {
      if (!projectId) return
      const [start, end] = getTimestamps(timeRange)
      const fields = await loadFields(searchTerm, start, end, filters)
      setFields(fields)
    },
    [projectId, loadFields],
  )

  const handleSetTimeRange = (range: TimeRange) => {
    setTimeRange(range)
    refreshLogs(searchTerm, range, filters)
    refreshFields(searchTerm, range, filters)
  }

  const handleSetFilters = (filters: Filter[]) => {
    setFilters(filters)
    refreshLogs(searchTerm, timeRange, filters)
    refreshFields(searchTerm, timeRange, filters)
  }

  const handleAddFilter = (filter: Filter) => {
    handleSetFilters([...filters, filter])
  }

  const handleRefresh = () => {
    refreshLogs(searchTerm, timeRange, filters)
    refreshFields(searchTerm, timeRange, filters)
  }

  const handleAddColumn = (column: Column) => {
    setColumns(sortColumns([...columns, column]))
  }

  const handleRemoveColumn = (field: string) => {
    setColumns(sortColumns(columns.filter((col) => col.name !== field)))
  }

  useEffect(() => {
    refreshLogs('', 'all', [])
    refreshFields('', 'all', [])
  }, [refreshLogs, refreshFields])

  useEffect(() => {
    if (live) {
      const interval = setInterval(() => {
        refreshLogs(searchTerm, timeRange, filters)
        refreshFields(searchTerm, timeRange, filters)
      }, 1000)
      return () => clearInterval(interval)
    }
  }, [live, timeRange, searchTerm, refreshLogs, filters, refreshFields])

  return (
    <>
      <LogsTopbar />
      <div className="h-full w-full flex flex-col gap-4 p-8 overflow-scroll">
        <LogSearchBar
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          timeRange={timeRange}
          setTimeRange={handleSetTimeRange}
          live={live}
          setLive={setLive}
          filters={filters}
          setFilters={handleSetFilters}
          refresh={handleRefresh}
        />
        <div className="w-full flex-1 basis-0 flex flex-row gap-4 overflow-hidden">
          <LogFields
            fields={fields}
            addFilter={handleAddFilter}
            columns={columns}
            addColumn={handleAddColumn}
            removeColumn={handleRemoveColumn}
          />
          <LogContainer
            logs={logs}
            columns={columns}
            setColumns={setColumns}
            selectedLog={selected}
            selectLog={setSelected}
          />
          {selected && (
            <LogDetails log={selected} close={() => setSelected(undefined)} />
          )}
        </div>
      </div>
    </>
  )
}

function sortColumns(columns: Column[]): Column[] {
  return columns.sort((a, b) => columnPriority(a) - columnPriority(b))
}

function columnPriority(column: Column): number {
  if (column.name === 'timestamp') return 0
  if (column.name === 'level') return 1
  if (column.name === 'resource.service.name') return 2
  if (column.name === 'body') return 3
  if (column.name.startsWith('resource.')) return 4
  if (column.name.startsWith('attributes.')) return 5
  return 6
}

export default Logs
