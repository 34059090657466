import { useCallback } from 'react'
import useQuery from '../../services/useQuery'
import { useRecoilValue } from 'recoil'
import { projectIdState } from '../../../state/state'
import { Filter } from '../../../backend/types'

const useLoadLogs = () => {
  const query = useQuery()

  const projectId = useRecoilValue(projectIdState)

  const loadLogs = useCallback(
    async (
      term: string,
      startTime?: string,
      endTime?: string,
      filters: Filter[] = [],
      limit: number = 500,
      offset?: number,
    ) => {
      if (!projectId) return
      const logs = await query.logsQuery({
        project_id: projectId,
        search_term: term,
        limit: limit,
        offset: offset,
        start_timestamp: startTime,
        end_timestamp: endTime,
        filters: filters,
      })
      return logs.logs
    },
    [query, projectId],
  )

  return loadLogs
}

export default useLoadLogs
