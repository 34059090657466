import useLoadOrganizationMembers from '../data/load/useLoadOrganizationMembers'
import useBackend from '../services/useBackend'

const useDeleteOrganizationMember = () => {
  const backend = useBackend()
  const loadOrganizationMembers = useLoadOrganizationMembers()

  const deleteOrganizationMember = async (
    organization_id: string,
    user_id: string,
  ) => {
    await backend.deleteOrganizationMember(organization_id, user_id)
    await loadOrganizationMembers()
  }

  return deleteOrganizationMember
}

export default useDeleteOrganizationMember
