import { useRecoilValue } from 'recoil'
import useBackend from '../services/useBackend'
import { organizationIdState } from '../../state/state'
import useLoadOrganizationInfo from '../data/load/useLoadOrganizationInfo'

export const useUpdateOrganizationName = () => {
  const backend = useBackend()

  const organizationId = useRecoilValue(organizationIdState)
  const loadOrganizationInfo = useLoadOrganizationInfo()

  return async (name: string) => {
    if (!organizationId) return
    await backend.updateOrganization(organizationId, name)
    await loadOrganizationInfo()
  }
}
