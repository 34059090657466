import { cn } from '../../../utils/cn'
import StepNumber from '../StepNumber/StepNumber'

const Step = ({
  number,
  title,
  children,
  disabled,
}: {
  number: number
  title: string
  children: React.ReactNode
  disabled?: boolean
}) => {
  return (
    <div
      className={cn(
        'relative w-full flex flex-row items-start justify-start gap-4 mb-16',
        disabled ? 'pointer-events-none select-none' : '',
      )}
    >
      <StepNumber number={number} />
      <div className="w-full flex flex-col items-start justify-start gap-2">
        <div className="h-[32px] flex items-center justify-start gap-2">
          <h2 className="text-[20px] font-semibold text-text-1">{title}</h2>
        </div>
        {children}
      </div>
      {disabled && <div className="absolute inset-0 bg-background/70" />}
    </div>
  )
}

export default Step
