interface PopupContainerProps {
  children: React.ReactNode
}

const PopupContainer: React.FC<PopupContainerProps> = ({ children }) => {
  return (
    <div className="w-[500px] flex flex-col border border-panel-border rounded-lg bg-background">
      {children}
    </div>
  )
}

export default PopupContainer
