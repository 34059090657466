interface FullscreenProps {
  children: React.ReactNode
}

const Fullscreen: React.FC<FullscreenProps> = ({ children }) => {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-background/80 backdrop-blur-sm z-50">
      {children}
    </div>
  )
}

export default Fullscreen
