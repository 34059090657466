interface PopupHeaderProps {
  children: React.ReactNode
}

const PopupHeader: React.FC<PopupHeaderProps> = ({ children }) => {
  return (
    <div className="w-full flex items-center justify-between px-4 py-2 border-b border-panel-border">
      {children}
    </div>
  )
}

export default PopupHeader
