import { useRef } from 'react'
import { useState } from 'react'
import { useRecoilValue } from 'recoil'
import { projectIdState } from '../../../state/state'
import { projectsState } from '../../../state/state'
import useSwitchProject from '../../../hooks/actions/useSwitchProject'
import ProjectPopup from './ProjectPopup/ProjectPopup'
import { ChevronDown } from 'lucide-react'

const TopbarProject: React.FC = () => {
  const [open, setOpen] = useState(false)
  const projectRef = useRef<HTMLDivElement>(null)

  const projects = useRecoilValue(projectsState)
  const projectId = useRecoilValue(projectIdState)
  const switchProject = useSwitchProject()

  return (
    <div
      ref={projectRef}
      onClick={() => setOpen(!open)}
      className="relative flex items-center gap-1 text-[12px] font-semibold text-text-2 cursor-pointer select-none"
    >
      {projectId ? projects?.find((p) => p.id === projectId)?.name : 'Project'}
      <ChevronDown size={12} className={'text-text-2'} />
      <ProjectPopup
        projectId={projectId || ''}
        projects={projects || []}
        setProject={switchProject}
        open={open}
        close={() => setOpen(false)}
        sourceRef={projectRef}
      />
    </div>
  )
}

export default TopbarProject
