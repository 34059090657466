import { InputHTMLAttributes } from 'react'
import { cn } from '../../../utils/cn'

interface LabeledInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string
}

const LabeledInput: React.FC<LabeledInputProps> = ({
  label,
  className,
  ...props
}) => {
  return (
    <div
      className={cn(
        'w-full flex flex-col items-start justify-center gap-2',
        className,
      )}
    >
      {label && <label className="text-text-2 text-[14px]">{label}</label>}
      <input
        {...props}
        className="w-full h-[36px] px-2 rounded-md border border-panel-border text-text-1 text-[14px]"
      />
    </div>
  )
}

export default LabeledInput
