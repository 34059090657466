import React from 'react'
import { FilterIcon } from 'lucide-react'
import { LogFieldsResponse } from '../../../../../../backend/types'
import { Column } from '../../Logs'
import { cn } from '../../../../../../utils/cn'

interface FieldsContainerProps {
  fields: LogFieldsResponse
  setFilterField: (field: string) => void

  columns: Column[]
  addColumn: (column: Column) => void
  removeColumn: (field: string) => void

  expanded: boolean
}

const FieldsContainer: React.FC<FieldsContainerProps> = ({
  fields,
  setFilterField,
  columns,
  addColumn,
  removeColumn,
  expanded,
}) => {
  const handleAddColumn = (display: string) => {
    switch (display) {
      case 'timestamp':
        addColumn({ name: display, width: 180 })
        break
      case 'body':
        addColumn({ name: display, width: 1000 })
        break
      case 'level':
        addColumn({ name: display, width: 100 })
        break
      case 'resource.service.name':
        addColumn({ name: display, width: 180 })
        break
      default:
        addColumn({ name: display, width: 300 })
    }
  }

  const handleRemoveColumn = (display: string) => {
    removeColumn(display)
  }

  return (
    <div className="p-2 flex flex-col gap-2">
      <div className="flex flex-col gap-2 font-mono">
        <FieldRow
          display={'timestamp'}
          value={fields.total}
          total={fields.total}
          hasColumn={columns.some((column) => column.name === 'timestamp')}
          hasFilter={false}
          addColumn={() => handleAddColumn('timestamp')}
          removeColumn={() => handleRemoveColumn('timestamp')}
          setFilterField={() => setFilterField('timestamp')}
          expanded={expanded}
        />
        <FieldRow
          display={'body'}
          value={fields.total}
          total={fields.total}
          hasColumn={columns.some((column) => column.name === 'body')}
          hasFilter={false}
          addColumn={() => handleAddColumn('body')}
          removeColumn={() => handleRemoveColumn('body')}
          setFilterField={() => setFilterField('body')}
          expanded={expanded}
        />
        <FieldRow
          display={'level'}
          value={fields.total}
          total={fields.total}
          hasColumn={columns.some((column) => column.name === 'level')}
          hasFilter={true}
          addColumn={() => handleAddColumn('level')}
          removeColumn={() => handleRemoveColumn('level')}
          setFilterField={() => setFilterField('severity_name')}
          expanded={expanded}
        />
        {Object.entries(fields.resources)
          .sort((a, b) => b[1] - a[1])
          .map(([key, value]) => (
            <FieldRow
              key={key}
              display={key}
              value={value}
              total={fields.total}
              hasColumn={columns.some(
                (column) => column.name === `resource.${key}`,
              )}
              hasFilter={true}
              addColumn={() => handleAddColumn(`resource.${key}`)}
              removeColumn={() => handleRemoveColumn(`resource.${key}`)}
              setFilterField={() => setFilterField(`resource.${key}`)}
              expanded={expanded}
            />
          ))}
        {Object.entries(fields.attributes)
          .sort((a, b) => b[1] - a[1])
          .map(([key, value]) => (
            <FieldRow
              key={key}
              display={key}
              value={value}
              total={fields.total}
              hasColumn={columns.some(
                (column) => column.name === `attributes.${key}`,
              )}
              hasFilter={true}
              addColumn={() => handleAddColumn(`attributes.${key}`)}
              removeColumn={() => handleRemoveColumn(`attributes.${key}`)}
              setFilterField={() => setFilterField(`attributes.${key}`)}
              expanded={expanded}
            />
          ))}
      </div>
    </div>
  )
}

interface FieldRowProps {
  display: string
  value: number
  total: number
  hasColumn: boolean
  hasFilter: boolean
  addColumn: () => void
  removeColumn: () => void
  setFilterField: () => void
  expanded: boolean
}

function FieldRow({
  display,
  value,
  total,
  hasColumn,
  hasFilter,
  addColumn,
  removeColumn,
  setFilterField,
  expanded,
}: FieldRowProps) {
  return (
    <div className="flex gap-2 items-center text-text-1">
      <div
        className={cn(
          'flex-1 hover:text-text-1 cursor-pointer select-none',
          hasColumn ? 'text-text-1' : 'text-text-2',
        )}
        onClick={() => {
          if (hasColumn) {
            removeColumn()
          } else {
            addColumn()
          }
        }}
      >
        {display}
      </div>
      {expanded && (
        <>
          <div className="flex-none w-[45px] text-text-2 text-right">
            {formatCount(value)}
          </div>
          <div className="flex-none w-[40px] text-text-2 text-right">
            {formatPercentage(value, total)}
          </div>
        </>
      )}
      <div className="p-[2px]" onClick={() => setFilterField()}>
        {hasFilter && (
          <FilterIcon className="w-3 h-3 text-text-2 hover:text-text-1 cursor-pointer" />
        )}
        {!hasFilter && <div className="w-3 h-3" />}
      </div>
    </div>
  )
}

function formatCount(count: number) {
  if (count > 1000000) {
    const m = count / 1000000
    return `${m.toFixed(1)}M`
  } else if (count > 1000) {
    const k = count / 1000
    return `${k.toFixed(1)}k`
  }
  return count
}

function formatPercentage(value: number, total: number) {
  if (total === 0) {
    return '100%'
  }
  const p = (value / total) * 100
  if (p > 100) {
    return '100%'
  } else if (p < 1) {
    return '<1%'
  }
  return `${p.toFixed(0)}%`
}

export default FieldsContainer
