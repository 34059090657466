import { InputHTMLAttributes, useRef, useEffect } from 'react'
import { cn } from '../../../utils/cn'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string
  focusOnMount?: boolean
}

const Input: React.FC<InputProps> = ({ className, focusOnMount, ...props }) => {
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (focusOnMount && inputRef.current) {
      inputRef.current.focus()
      inputRef.current.select()
    }
  }, [focusOnMount])

  return (
    <input
      {...props}
      ref={inputRef}
      className={cn(
        className,
        'w-full h-[36px] px-2 rounded-md border border-panel-border text-text-1',
      )}
    />
  )
}

export default Input
