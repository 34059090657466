import { useState } from 'react'
import Button from '../../../../../library/Button/Button'
import useCreateToken from '../../../../../../hooks/actions/useCreateToken'
import Step from '../../../../../library/Step/Step'
import CodeBlock from '../../../../../library/CodeBlock/CodeBlock'

interface NodeDocsProps {
  close: () => void
}

const url = process.env.REACT_APP_LOG_ADDRESS || 'log.vigilant.run:4317'

const NodeDocs: React.FC<NodeDocsProps> = ({ close }) => {
  const createToken = useCreateToken()
  const [token, setToken] = useState('')

  const handleCreateToken = async () => {
    const token = await createToken(
      'Node.js SDK Token',
      'Token for Node.js SDK.',
    )
    if (token) setToken(token.token)
  }

  return (
    <div className="flex flex-col w-full max-w-[800px] p-8">
      <div className="w-full h-full flex flex-col items-start justify-start">
        <div className="w-full flex flex-row items-center justify-between mb-4">
          <div className="text-[24px] font-semibold text-text-1">
            Typescript Setup
          </div>
          <Button variant="outline" onClick={close}>
            Back
          </Button>
        </div>
        <p className="text-[14px] text-text-1 leading-6 mb-4">
          Follow these instructions to setup the Vigilant Node.js SDK.
        </p>

        <Step number={1} title="Install the SDK">
          <p className="text-[14px] text-text-1 leading-6">
            Install the SDK using the following command:
          </p>
          <CodeBlock
            displayName={'Bash'}
            language={'bash'}
            children={'npm install vigilant-js'}
          />
        </Step>

        <Step number={2} title="Create a Token">
          <p className="text-[14px] text-text-1 leading-6">
            Create a secret token to authenticate the SDK with Vigilant.
          </p>
          <div className="flex flex-col items-start justify-start">
            <Button
              disabled={!!token}
              variant="cta"
              onClick={handleCreateToken}
            >
              Create Token
            </Button>
          </div>
        </Step>

        <Step number={3} title="Use the SDK" disabled={!token}>
          <p className="text-[14px] text-text-1 leading-6">
            Use the SDK to send logs to Vigilant.
          </p>
          <CodeBlock
            displayName={'Typescript'}
            language={'typescript'}
            children={getCodeSnippet(url, token)}
          />
        </Step>

        <Step number={4} title="Run your application" disabled={!token}>
          <p className="text-[14px] text-text-1 leading-6">
            Run your application and you should start seeing logs in Vigilant.
          </p>
        </Step>

        <div className="w-full h-[100px]"></div>
      </div>
    </div>
  )
}

export default NodeDocs

function getCodeSnippet(url: string, token: string) {
  return `import { AutocaptureLogger } from 'vigilant-js'

// Initialize the logger
const logger = new AutocaptureLogger({
  url: '${url}',
  token: '${token}',
  name: 'sample-app'
})

// Enable the logger
logger.enable()

// Log a message using the logger
logger.info('You can log messages like this')

// Log a message using console.log
console.log('But you can also use console.log')

// Shutdown the logger before your application exits
await logger.shutdown()
`
}
