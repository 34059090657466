import { Ellipsis } from 'lucide-react'
import { LogEntry } from '../../../../../../state/types'
import { cn } from '../../../../../../utils/cn'
import { useState } from 'react'

interface LogRowProps {
  log: LogEntry

  selected: boolean
  selectLog: (log: LogEntry | undefined) => void

  columnWidths: number[]
  columnExtractors: ((log: LogEntry) => React.ReactNode)[]
}

const LogRow: React.FC<LogRowProps> = ({
  log,
  selected,
  selectLog,
  columnWidths,
  columnExtractors,
}) => {
  const [hovered, setHovered] = useState(false)

  return (
    <div
      className={cn(
        'relative w-full flex flex-row flex-none border-b border-panel-border',
        selected ? 'bg-panel-background' : '',
      )}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {columnExtractors.map((extractor, index) => (
        <div
          className="flex-none px-4 py-1 overflow-hidden"
          key={index}
          style={{ width: columnWidths[index] }}
        >
          {extractor(log)}
        </div>
      ))}
      {hovered && (
        <div className="h-full absolute top-0 right-3 flex-1 flex items-center justify-end">
          <Ellipsis
            className="h-4 w-4 text-text-2 hover:text-text-1 cursor-pointer"
            onClick={() => selectLog(log)}
          />
        </div>
      )}
    </div>
  )
}

export default LogRow
