import { useLocation } from 'react-router-dom'
import Topbar from '../../../../library/Topbar/Topbar'
import TopbarDivider from '../../../../library/TopbarDivider/TopbarDivider'
import TopbarOrganization from '../../../../library/TopbarOrganization/TopbarOrganization'
import TopbarProject from '../../../../library/TopbarProject/TopbarProject'
import _ from 'lodash'

const SettingsTopbar: React.FC = () => {
  const location = useLocation()

  return (
    <Topbar>
      <TopbarOrganization />
      <TopbarDivider />
      <TopbarProject />
      <TopbarDivider />
      <div className="text-[12px] font-semibold text-text-2">Settings</div>
      <TopbarDivider />
      <div className="text-[12px] font-semibold text-text-1">
        {_.capitalize(location.pathname.split('/').pop() ?? '')}
      </div>
    </Topbar>
  )
}

export default SettingsTopbar
