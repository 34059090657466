import { useRecoilValue } from 'recoil'
import { organizationIdState } from '../../state/state'
import useBackend from '../services/useBackend'

const useCreateOrganizationInvite = () => {
  const backend = useBackend()

  const organizationId = useRecoilValue(organizationIdState)

  const createOrganizationInvite = async (email: string) => {
    if (!organizationId) return
    const response = await backend.createOrganizationInvite(
      organizationId,
      email,
    )
    return response.token
  }

  return createOrganizationInvite
}

export default useCreateOrganizationInvite
