import { useRef, useState } from 'react'
import { ChevronDown, ChevronUp } from 'lucide-react'
import { cn } from '../../../../../../utils/cn'
import Button from '../../../../../library/Button/Button'
import { TimeRange } from '../../../../../../utils/time'

interface TimeSelectorProps {
  timeRange: TimeRange
  setTimeRange: (timeRange: TimeRange) => void
}

const TimeSelector = ({ timeRange, setTimeRange }: TimeSelectorProps) => {
  const sourceRef = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState(false)

  const handleSetTimeRange = (timeRange: TimeRange) => {
    setTimeRange(timeRange)
    setOpen(false)
  }

  return (
    <div
      ref={sourceRef}
      className="relative flex flex-none items-center gap-2 text-[14px] text-text-1 border border-panel-border rounded-md select-none"
    >
      <div
        onClick={() => setOpen(!open)}
        className="px-4 py-2 flex items-center gap-2 cursor-pointer"
      >
        {formatLabel(timeRange)}
        {open ? (
          <ChevronUp className="w-4 h-4" />
        ) : (
          <ChevronDown className="w-4 h-4" />
        )}
      </div>
      {open && (
        <div
          className={cn(
            'absolute top-full mt-2 left-1/2 w-[360px] flex flex-col bg-background border border-panel-border rounded-md z-[2] translate-x-[-50%]',
          )}
        >
          <div className="w-full p-2 flex flex-col gap-1 items-start">
            <div className="text-text-1 font-semibold text-[12px] text-center mb-2">
              Presets
            </div>
            <div className="w-full flex flex-wrap gap-2 items-center">
              {timeRangeOptions.map((option) => (
                <Button
                  key={timeRangeToKey(option)}
                  className="w-[108px]"
                  variant="outline"
                  onClick={() => handleSetTimeRange(option)}
                >
                  {formatLabel(option)}
                </Button>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

function timeRangeToKey(timeRange: TimeRange): string {
  if (typeof timeRange === 'string') {
    return timeRange
  }
  return `${timeRange[0]}-${timeRange[1]}`
}

function formatLabel(timeRange: TimeRange): string {
  switch (timeRange) {
    case 'all':
      return 'All Time'
    default:
      return `Last ${timeRange}`
  }
}

const timeRangeOptions: TimeRange[] = ['1h', '6h', '24h', '7d', 'all']

export default TimeSelector
