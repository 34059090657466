import { useState } from 'react'
import useNav, { LinkKey } from '../../../hooks/utils/useNav'
import OnboardingCard from '../../library/OnboardingCard/OnboardingCard'
import LabeledInput from '../../library/LabeledInput/LabledInput'
import Button from '../../library/Button/Button'
import useSignup from '../../../hooks/actions/useSignup'
import useLoggedIn from '../../../hooks/utils/useLoggedIn'

const Signup: React.FC = () => {
  const loggedIn = useLoggedIn()

  const navigate = useNav()
  const signup = useSignup()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (password !== confirmPassword) return
    await signup(email, password)
  }

  if (loggedIn) return <></>

  return (
    <div className="h-screen w-screen flex items-center justify-center bg-background">
      <OnboardingCard>
        <form onSubmit={handleSubmit} className="w-full flex flex-col gap-6">
          <div className="w-full flex flex-col items-start justify-center gap-1.5">
            <h2 className="text-[24px] font-semibold text-text-1">Sign Up</h2>
            <p className="text-text-2 text-[14px]">
              Enter your email to create your account
            </p>
          </div>
          <div className="w-full flex flex-col items-start justify-center gap-4">
            <LabeledInput
              label={'Email'}
              placeholder={'Enter your email'}
              value={email}
              type="email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <LabeledInput
              label={'Password'}
              placeholder={'Enter your password'}
              value={password}
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <LabeledInput
              label={'Confirm Password'}
              placeholder={'Confirm your password'}
              value={confirmPassword}
              type="password"
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
          <div className="w-full flex flex-col items-start justify-center gap-4">
            <Button type="submit" className="w-full">
              Create Account
            </Button>
            <p className="text-text-2 text-[14px]">
              Already have an account?{' '}
              <span
                className="underline text-text-1 cursor-pointer"
                onClick={() => navigate(LinkKey.Login)}
              >
                Log In
              </span>
            </p>
          </div>
        </form>
      </OnboardingCard>
    </div>
  )
}

export default Signup
