import { cn } from '../../../utils/cn'

interface TableLabelProps {
  children: React.ReactNode
  className?: string
  last?: boolean
}

const TableLabel: React.FC<TableLabelProps> = ({
  children,
  className,
  last,
}) => {
  return (
    <div
      className={cn(
        'p-4 text-[14px] text-text-1 font-semibold sticky top-0 border-b border-panel-border bg-background',
        className,
        last ? 'border-b-0' : '',
      )}
    >
      {children}
    </div>
  )
}

export default TableLabel
