import { useRef, useState } from 'react'
import FieldsContainer from './FieldsContainer/FieldsContainer'
import { ChevronsRight, Equal, EqualNot, X } from 'lucide-react'
import {
  LogFieldsResponse,
  Filter,
  FilterOperator,
  ValueCount,
} from '../../../../../backend/types'
import useLoadOptions from '../../../../../hooks/data/load/useLoadOptions'
import { Column } from '../Logs'
import { cn } from '../../../../../utils/cn'
import useClickOutside from '../../../../../hooks/utils/useClickOutside'

interface LogFieldsProps {
  fields: LogFieldsResponse
  addFilter: (filter: Filter) => void

  columns: Column[]
  addColumn: (column: Column) => void
  removeColumn: (field: string) => void
}

const LogFields: React.FC<LogFieldsProps> = ({
  fields,
  addFilter,
  columns,
  addColumn,
  removeColumn,
}) => {
  const loadOptions = useLoadOptions()

  const sourceRef = useRef<HTMLDivElement>(null)
  const popupRef = useRef<HTMLDivElement>(null)
  useClickOutside(popupRef, () => setFilterField(undefined), sourceRef)

  const [filterField, setFilterField] = useState<string | undefined>(undefined)
  const [options, setOptions] = useState<ValueCount[]>([])
  const [expanded, setExpanded] = useState(true)

  const handleSetField = async (field: string) => {
    if (field === filterField) {
      setFilterField(undefined)
      return
    } else {
      const options = await loadOptions(field)
      setOptions(options)
      setFilterField(field)
    }
  }

  const handleAddFilter = (
    field: string,
    value: string,
    operator: FilterOperator,
  ) => {
    addFilter({ field, value, operator })
    setFilterField(undefined)
  }

  const addEqual = (field: string, value: string) => {
    handleAddFilter(field, value, 'equals')
  }

  const addNotEqual = (field: string, value: string) => {
    handleAddFilter(field, value, 'not_equals')
  }

  return (
    <div
      className="flex-none flex flex-col text-[12px] text-text-1 border border-panel-border rounded-lg select-none"
      style={{ width: expanded ? '320px' : '200px' }}
    >
      <div className="flex gap-2 justify-between items-center p-2 border-b border-panel-border">
        <div className="text-text-1 font-mono">properties</div>
        <ChevronsRight
          onClick={() => setExpanded(!expanded)}
          className={cn(
            'w-4 h-4 text-text-2 hover:text-text-1 cursor-pointer',
            expanded ? 'rotate-180' : '',
          )}
        />
      </div>
      <div ref={sourceRef} className="relative flex-1 z-[2]">
        <FieldsContainer
          fields={fields}
          setFilterField={handleSetField}
          columns={columns}
          addColumn={addColumn}
          removeColumn={removeColumn}
          expanded={expanded}
        />
        {filterField && (
          <div
            ref={popupRef}
            className="absolute w-[480px] max-h-[600px] top-0 left-full ml-2 bg-background z-[2] border border-panel-border rounded-lg shadow-md flex flex-col"
          >
            <div className="p-2 text-text-1 font-mono border-b border-panel-border flex justify-between items-center">
              <span>{getField(filterField)}</span>
              <X
                onClick={() => setFilterField(undefined)}
                className="w-4 h-4 text-text-2 hover:text-text-1 cursor-pointer"
              />
            </div>
            <div className="px-2 flex flex-col text-text-1 font-mono overflow-y-scroll">
              <div className="sticky top-0 py-2 flex justify-between items-center bg-background">
                <div className="flex-1 text-text-1 font-semibold">Value</div>
                <div className="w-[85px] text-text-1 text-right font-semibold">
                  Occurrences
                </div>
                <div className="w-[80px]"></div>
              </div>
              <div className="pb-2 flex flex-col gap-2">
                {options.map((option) => (
                  <div className="flex justify-between items-center">
                    <div className="flex-1 text-text-1 overflow-scroll whitespace-nowrap">
                      {option.value}
                    </div>
                    <div className="w-[85px] text-text-1 text-right">
                      {formatCount(option.count)}
                    </div>
                    <div className="w-[80px] flex gap-2 justify-end">
                      <Equal
                        className="w-4 h-4 text-text-2 hover:text-text-1 cursor-pointer"
                        onClick={() => addEqual(filterField, option.value)}
                      />
                      <EqualNot
                        className="w-4 h-4 text-text-2 hover:text-text-1 cursor-pointer"
                        onClick={() => addNotEqual(filterField, option.value)}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

function getField(field: string) {
  if (field.startsWith('attributes.')) {
    return field.replace('attributes.', '')
  } else if (field.startsWith('resource.')) {
    return field.replace('resource.', '')
  } else if (field.startsWith('severity_name')) {
    return 'level'
  }
  return field
}

function formatCount(count: number) {
  if (count > 1000000) {
    const m = count / 1000000
    return `${m.toFixed(1)}M`
  } else if (count > 1000) {
    const k = count / 1000
    return `${k.toFixed(1)}k`
  }
  return count
}

export default LogFields
