import { useState, useEffect } from 'react'
import { cn } from '../../../../../../utils/cn'
import { Resizable } from 're-resizable'
import { Column } from '../../Logs'

interface LogHeaderProps {
  columns: Column[]
  setColumns: (columns: Column[]) => void
}

const LogHeader: React.FC<LogHeaderProps> = ({ columns, setColumns }) => {
  const [draggingColumns, setDraggingColumns] = useState<Column[]>(columns)
  const [dragging, setDragging] = useState(false)

  useEffect(() => {
    if (dragging) return
    setDraggingColumns(columns)
  }, [columns, dragging])

  return (
    <div className="w-full sticky top-0 flex flex-row bg-background z-[1] border-b border-panel-border">
      {draggingColumns.map((column, index) => (
        <Resizable
          key={`${column.name}-${index}`}
          className={cn(
            'flex items-center justify-start border-r border-panel-border px-4 py-2 overflow-x-hidden',
            index === columns.length - 1 ? 'border-r-0' : '',
          )}
          defaultSize={{ width: column.width }}
          onResizeStart={() => {
            setDragging(true)
          }}
          onResize={(e, direction, ref, d) => {
            setColumns(
              columns.map((col, i) => {
                if (i === index) {
                  return {
                    ...col,
                    width: Math.max(50, draggingColumns[index].width + d.width),
                  }
                }
                return col
              }),
            )
          }}
          onResizeStop={() => {
            setDragging(false)
          }}
          minWidth={50}
          maxWidth={1000}
          enable={{
            right: true,
            top: false,
            bottom: false,
            left: false,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false,
          }}
          handleClasses={{
            right: 'resize-handle',
          }}
          handleStyles={{
            right: {
              width: '8px',
              height: '100%',
              position: 'absolute',
              cursor: 'col-resize',
              right: '-4px',
            },
          }}
        >
          <div
            className={cn(
              'bg-background text-text-1 whitespace-nowrap text-ellipsis overflow-hidden',
            )}
          >
            {formatColumnName(column)}
          </div>
        </Resizable>
      ))}
    </div>
  )
}

function formatColumnName(column: Column) {
  if (column.name.startsWith('resource.')) {
    return column.name.replace('resource.', '')
  } else if (column.name.startsWith('attributes.')) {
    return column.name.replace('attributes.', '')
  }
  return column.name
}

export default LogHeader
