import { useSetRecoilState } from 'recoil'
import useBackend from '../services/useBackend'
import { projectIdState } from '../../state/state'

const useSelectProject = () => {
  const backend = useBackend()
  const setProjectId = useSetRecoilState(projectIdState)

  return async (organizationId: string, projectId: string) => {
    await backend.authProject(organizationId, projectId)
    setProjectId(projectId)
  }
}

export default useSelectProject
