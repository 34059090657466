import { useRef } from 'react'
import { useState } from 'react'
import { useRecoilValue } from 'recoil'
import { organizationIdState, organizationsState } from '../../../state/state'
import useSwitchOrganization from '../../../hooks/actions/useSwitchOrganization'
import OrganizationPopup from './OrganizationPopup/OrganizationPopup'
import { Building2 } from 'lucide-react'

const TopbarOrganization: React.FC = () => {
  const [open, setOpen] = useState(false)
  const projectRef = useRef<HTMLDivElement>(null)

  const organizations = useRecoilValue(organizationsState)
  const organizationId = useRecoilValue(organizationIdState)
  const switchOrganization = useSwitchOrganization()

  return (
    <div
      ref={projectRef}
      onClick={() => setOpen(!open)}
      className="relative flex items-center gap-2 text-[12px] font-semibold text-text-2 cursor-pointer select-none"
    >
      <Building2 size={16} className={'text-text-2'} />
      <OrganizationPopup
        organizationId={organizationId || ''}
        organizations={organizations || []}
        selectOrganization={switchOrganization}
        open={open}
        close={() => setOpen(false)}
        sourceRef={projectRef}
      />
    </div>
  )
}

export default TopbarOrganization
