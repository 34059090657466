import { useRecoilValue } from 'recoil'
import { organizationIdState } from '../../state/state'
import useBackend from '../services/useBackend'
import useLoadProjects from '../data/load/useLoadProjects'
import useSwitchProject from './useSwitchProject'

const useCreateProject = () => {
  const backend = useBackend()

  const organizationId = useRecoilValue(organizationIdState)
  const loadProjects = useLoadProjects()
  const switchProject = useSwitchProject()

  const createProject = async (name: string) => {
    if (!organizationId) return
    try {
      const response = await backend.createProject(organizationId, name)
      await loadProjects()
      await switchProject(response.id)
    } catch (e) {}
  }

  return createProject
}

export default useCreateProject
