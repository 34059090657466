import { useCallback } from 'react'
import useQuery from '../../services/useQuery'
import { useRecoilValue } from 'recoil'
import { projectIdState } from '../../../state/state'

const useLoadErrors = () => {
  const query = useQuery()

  const projectId = useRecoilValue(projectIdState)

  const loadErrors = useCallback(
    async (
      term: string,
      startTime?: string,
      endTime?: string,
      limit: number = 500,
      offset?: number,
    ) => {
      if (!projectId) return
      const errors = await query.errorsQuery({
        project_id: projectId,
        search_term: term,
        limit: limit,
        offset: offset,
        start_timestamp: startTime,
        end_timestamp: endTime,
      })
      return errors.errors
    },
    [query, projectId],
  )

  return loadErrors
}

export default useLoadErrors
