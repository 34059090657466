import { cn } from '../../../utils/cn'

interface PopupBodyProps {
  children: React.ReactNode
  className?: string
}

const PopupBody: React.FC<PopupBodyProps> = ({ children, className }) => {
  return (
    <div className={cn('flex flex-col p-4 gap-4 items-start', className)}>
      {children}
    </div>
  )
}

export default PopupBody
