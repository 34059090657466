import { X } from 'lucide-react'
import { Filter, FilterOperator } from '../../../../../../backend/types'

interface FilterBlockProps {
  filter: Filter
  remove: () => void
}

const FilterBlock: React.FC<FilterBlockProps> = ({ filter, remove }) => {
  return (
    <div className="flex items-center justify-center text-[12px] gap-1 text-text-1 bg-panel-background rounded-md px-2 py-1 font-mono">
      <div className="flex gap-1 items-center">
        {filter.field} {translateFilterOperator(filter.operator)} {filter.value}
      </div>
      <X className="w-4 h-4 text-text-1 cursor-pointer" onClick={remove} />
    </div>
  )
}

function translateFilterOperator(operator: FilterOperator) {
  switch (operator) {
    case 'equals':
      return '='
    case 'not_equals':
      return '!='
    case 'contains':
      return 'contains'
    case 'not_contains':
      return 'not contains'
  }
}

export default FilterBlock
