import { Search } from 'lucide-react'
import { useCallback, useEffect, useRef } from 'react'
import Button from '../../../../library/Button/Button'
import TimeSelector from './TimeSelector/TimeSelector'
import { TimeRange } from '../../../../../utils/time'

interface ErrorsSearchBarProps {
  searchTerm: string
  setSearchTerm: (searchTerm: string) => void

  timeRange: TimeRange
  setTimeRange: (timeRange: TimeRange) => void

  refresh: () => void
}

const ErrorsSearchBar: React.FC<ErrorsSearchBarProps> = ({
  searchTerm,
  setSearchTerm,
  timeRange,
  setTimeRange,
  refresh,
}) => {
  const searchInputRef = useRef<HTMLInputElement>(null)

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault()
        if (searchTerm) {
          refresh()
        }
      } else if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
        searchInputRef.current?.focus()
        setSearchTerm('')
        refresh()
      } else if (event.key === 'Escape') {
        searchInputRef.current?.blur()
        setSearchTerm('')
        refresh()
      }
    },
    [searchTerm, refresh, setSearchTerm],
  )

  const handleSearchBlur = useCallback(() => {
    if (searchTerm === '') refresh()
  }, [refresh, searchTerm])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [handleKeyDown])

  return (
    <div className="flex flex-row gap-4 items-start">
      <div className="w-full flex flex-col gap-2 items-center border border-panel-border rounded-md p-2">
        <div className="w-full flex flex-row gap-2 items-center">
          <Search className="w-4 h-4 flex-none text-text-1" />
          <input
            ref={searchInputRef}
            type="text"
            className="w-full text-text-1 text-[12px] placeholder:text-text-2 font-mono"
            placeholder="Search term (e.g. 'database')"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onBlur={handleSearchBlur}
          />
        </div>
      </div>
      <TimeSelector timeRange={timeRange} setTimeRange={setTimeRange} />
      <Button onClick={refresh}>
        Search <span className="text-[14px]">(↵)</span>
      </Button>
    </div>
  )
}

export default ErrorsSearchBar
