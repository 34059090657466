import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

export enum LinkKey {
  Signup = 'signup',
  Login = 'login',
  NewOrganization = 'organization',
  NewProject = 'project',
  Logs = 'logs',
  Errors = 'errors',
  API = 'api',
  Integrations = 'integrations',
  AccountSettings = 'accountSettings',
  OrganizationSettings = 'organizationSettings',
  ProjectSettings = 'projectSettings',
}

export const linkMap: Record<LinkKey, string> = {
  signup: '/signup',
  login: '/login',
  organization: '/organization',
  project: '/project',
  logs: '/logs',
  errors: '/errors',
  api: '/api',
  integrations: '/integrations',
  accountSettings: '/settings/account',
  organizationSettings: '/settings/organization',
  projectSettings: '/settings/project',
}

const isInternalLink = (key: LinkKey) => linkMap[key].startsWith('/')

const useNav = () => {
  const navigate = useNavigate()

  return useCallback(
    (key: LinkKey) => {
      let path = linkMap[key]

      if (isInternalLink(key)) {
        navigate(path)
      } else {
        window.open(path, '_blank')
      }
    },
    [navigate],
  )
}

export default useNav
