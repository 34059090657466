import { useEffect } from 'react'
import useLoadTokens from '../load/useLoadTokens'

const useInitTokens = () => {
  const loadTokens = useLoadTokens()

  useEffect(() => {
    loadTokens()
  }, [loadTokens])
}

export default useInitTokens
