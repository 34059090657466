import { useMemo } from 'react'
import { QueryService } from '../../backend/query'
import { HTTPService } from '../../backend/http'
import useAuthService from './useAuthService'

const { REACT_APP_QUERY_ADDRESS } = process.env
const SERVER_ADDRESS = REACT_APP_QUERY_ADDRESS
  ? REACT_APP_QUERY_ADDRESS
  : 'http://localhost:4200'

const useQuery = () => {
  const authService = useAuthService()

  return useMemo(() => {
    return new QueryService(new HTTPService(SERVER_ADDRESS), authService)
  }, [authService])
}

export default useQuery
