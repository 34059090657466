import { cn } from '../../../utils/cn'

interface TableValueProps {
  children: React.ReactNode
  last?: boolean
  className?: string
}

const TableValue: React.FC<TableValueProps> = ({
  children,
  className,
  last = false,
}) => {
  return (
    <div
      className={cn(
        'flex items-center justify-between gap-2 p-4 text-[14px] text-text-1 font-light',
        className,
        !last ? 'border-b border-panel-border' : '',
      )}
    >
      {children}
    </div>
  )
}

export default TableValue
