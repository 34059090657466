import { useCallback } from 'react'
import useQuery from '../../services/useQuery'
import { useRecoilValue } from 'recoil'
import { projectIdState } from '../../../state/state'

const useLoadOptions = () => {
  const query = useQuery()

  const projectId = useRecoilValue(projectIdState)

  const loadOptions = useCallback(
    async (field: string, startTime?: string) => {
      if (!projectId) return []
      const options = await query.logsOptions({
        project_id: projectId,
        field: field,
        start_timestamp: startTime,
      })
      return options.options
    },
    [query, projectId],
  )

  return loadOptions
}

export default useLoadOptions
