import { useSetRecoilState, useRecoilValue } from 'recoil'
import { projectIdState, tokensState } from '../../../state/state'
import useBackend from '../../services/useBackend'
import { useCallback } from 'react'

const useLoadTokens = () => {
  const backend = useBackend()
  const projectId = useRecoilValue(projectIdState)
  const setTokens = useSetRecoilState(tokensState)

  const loadTokens = useCallback(async () => {
    if (!projectId) return
    try {
      const { tokens } = await backend.getProjectTokens(projectId)
      setTokens(tokens)
    } catch (e) {}
  }, [backend, setTokens, projectId])

  return loadTokens
}

export default useLoadTokens
