import { useRef, useState } from 'react'
import { ChevronDown, ChevronUp } from 'lucide-react'
import { cn } from '../../../../../../utils/cn'
import Button from '../../../../../library/Button/Button'
import Input from '../../../../../library/Input/Input'
import { TimeRange } from '../../../../../../utils/time'

interface TimeSelectorProps {
  timeRange: TimeRange
  setTimeRange: (timeRange: TimeRange) => void
}

const TimeSelector = ({ timeRange, setTimeRange }: TimeSelectorProps) => {
  const sourceRef = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState(false)
  const [localStart, setLocalStart] = useState(getStartTimestamp(timeRange))
  const [localEnd, setLocalEnd] = useState(getEndTimestamp(timeRange))

  const handleSetTimeRange = (timeRange: TimeRange) => {
    setTimeRange(timeRange)
    setOpen(false)
  }

  const handleSubmitLocalTimes = () => {
    const start = parseTimestamp(localStart)
    const end = parseTimestamp(localEnd)
    setTimeRange([start, end])
    setOpen(false)
  }

  return (
    <div
      ref={sourceRef}
      className="relative flex flex-none items-center gap-2 text-[14px] text-text-1 border border-panel-border rounded-md select-none"
    >
      <div
        onClick={() => setOpen(!open)}
        className="px-4 py-2 flex items-center gap-2 cursor-pointer"
      >
        {getStartTimestamp(timeRange) || getEndTimestamp(timeRange) ? (
          <span>Custom Range</span>
        ) : (
          <span>{formatLabel(timeRange)}</span>
        )}
        {open ? (
          <ChevronUp className="w-4 h-4" />
        ) : (
          <ChevronDown className="w-4 h-4" />
        )}
      </div>
      {open && (
        <div
          className={cn(
            'absolute top-full mt-2 left-1/2 w-[360px] flex flex-col bg-background border border-panel-border rounded-md z-[2] translate-x-[-50%]',
          )}
        >
          <div className="w-full p-2 flex flex-col gap-1 items-start">
            <div className="text-text-1 font-semibold text-[12px] text-center mb-2">
              Presets
            </div>
            <div className="w-full flex flex-wrap gap-2 items-center">
              {timeRangeOptions.map((option) => (
                <Button
                  key={timeRangeToKey(option)}
                  className="w-[108px]"
                  variant="outline"
                  onClick={() => handleSetTimeRange(option)}
                >
                  {formatLabel(option)}
                </Button>
              ))}
            </div>
          </div>

          <div className="w-full p-2 flex flex-col gap-1 items-start">
            <div className="text-text-1 text-[12px] font-semibold text-center mb-2">
              Custom Range
            </div>
            <div className="w-full flex flex-col gap-2 items-start">
              <div className="w-full flex flex-col gap-1 items-start">
                <span className="text-text-2 text-[12px] font-mono text-center">
                  Start
                </span>
                <Input
                  className={cn('font-mono text-[12px]')}
                  value={localStart || ''}
                  placeholder={'2024-12-09T22:15:22+00:00'}
                  onChange={(value) => {
                    setLocalStart(value.target.value)
                  }}
                />
              </div>
              <div className="w-full flex flex-col gap-1 items-start">
                <span className="text-text-2 text-[12px] font-mono text-center">
                  End
                </span>
                <Input
                  className={cn('font-mono text-[12px]')}
                  value={localEnd || ''}
                  placeholder={'2024-12-09T22:15:22+00:00'}
                  onChange={(value) => {
                    setLocalEnd(value.target.value)
                  }}
                />
              </div>
              <Button variant="outline" onClick={handleSubmitLocalTimes}>
                Apply Range
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

function timeRangeToKey(timeRange: TimeRange): string {
  if (typeof timeRange === 'string') {
    return timeRange
  }
  return `${timeRange[0]}-${timeRange[1]}`
}

function getStartTimestamp(timeRange: TimeRange): string | null {
  if (Array.isArray(timeRange)) {
    return timeRange[0]
  }
  return null
}

function getEndTimestamp(timeRange: TimeRange): string | null {
  if (Array.isArray(timeRange)) {
    return timeRange[1]
  }
  return null
}

function formatLabel(timeRange: TimeRange): string {
  switch (timeRange) {
    case 'all':
      return 'All Time'
    default:
      if (
        Array.isArray(timeRange) &&
        timeRange[0] === null &&
        timeRange[1] === null
      ) {
        return 'All Time'
      }
      return `Last ${timeRange}`
  }
}

function parseTimestamp(timestamp: string | null): string | null {
  if (!timestamp) return null
  try {
    const date = new Date(timestamp)
    return date.toISOString()
  } catch (e) {
    return null
  }
}

const timeRangeOptions: TimeRange[] = [
  '30s',
  '5m',
  '15m',
  '1h',
  '6h',
  '24h',
  '3d',
  '7d',
  'all',
]

export default TimeSelector
