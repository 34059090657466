import { useEffect, useState } from 'react'
import useBackend from '../services/useBackend'
import useAuthService from '../services/useAuthService'
import useLogout from './useLogout'

const useLoggedOut = () => {
  const backend = useBackend()
  const auth = useAuthService()
  const logout = useLogout()

  const [loggedOut, setLoggedOut] = useState(true)

  useEffect(() => {
    const checkLoggedOut = async () => {
      try {
        await backend.refresh().then(() => {
          setLoggedOut(false)
        })
      } catch (e) {
        logout()
      }
    }
    checkLoggedOut()
  }, [auth, backend, logout])

  return loggedOut
}

export default useLoggedOut
