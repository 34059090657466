import {
  Blocks,
  Bug,
  HelpCircle,
  Key,
  Logs,
  Settings,
  User,
} from 'lucide-react'
import IconButton from '../../../library/IconButton/IconButton'
import { useLocation } from 'react-router-dom'
import useNav, { LinkKey, linkMap } from '../../../../hooks/utils/useNav'
import { useRecoilValue } from 'recoil'
import { userInfoState } from '../../../../state/state'
import useErrorsTabEnabled from '../../../../hooks/utils/useErrorTabEnabled'

const Sidebar: React.FC = () => {
  const location = useLocation()
  const navigate = useNav()

  const userInfo = useRecoilValue(userInfoState)
  const errorsTabEnabled = useErrorsTabEnabled()

  return (
    <div className="w-[240px] h-full py-4 flex flex-col flex-none items-start justify-start gap-4 bg-panel-background border-r border-panel-border">
      <div className="w-full px-3 flex flex-col gap-1">
        <IconButton
          text={'Logs'}
          icon={<Logs size={16} className={'text-text-1'} />}
          active={location.pathname === '/dashboard/logs'}
          onClick={() => navigate(LinkKey.Logs)}
        />
        {errorsTabEnabled && (
          <IconButton
            text={'Errors'}
            icon={<Bug size={16} className={'text-text-1'} />}
            active={location.pathname === '/dashboard/errors'}
            onClick={() => navigate(LinkKey.Errors)}
          />
        )}
        <IconButton
          text={'Integrations'}
          icon={<Blocks size={16} className={'text-text-1'} />}
          active={location.pathname === linkMap[LinkKey.Integrations]}
          onClick={() => navigate(LinkKey.Integrations)}
        />
        <IconButton
          text={'API'}
          icon={<Key size={16} className={'text-text-1'} />}
          active={location.pathname === '/dashboard/api'}
          onClick={() => navigate(LinkKey.API)}
        />
        <IconButton
          text={'Settings'}
          icon={<Settings size={16} className={'text-text-1'} />}
          active={location.pathname === '/dashboard/settings'}
          onClick={() => navigate(LinkKey.OrganizationSettings)}
        />
        <IconButton
          text={'Support'}
          icon={<HelpCircle size={16} className={'text-text-1'} />}
          onClick={() => window.open('mailto:izak@vigilant.run', '_blank')}
        />
      </div>
      <div className="w-full h-full px-3 flex flex-col justify-end">
        <div
          className="flex items-center justify-between w-full h-[32px] p-1 rounded-[6px] bg-transparent hover:bg-panel-border cursor-pointer select-none"
          onClick={() => navigate(LinkKey.AccountSettings)}
        >
          <div className="flex items-center gap-2">
            <User size={16} className={'text-text-1'} />
            <p className="text-[12px] text-text-1 whitespace-nowrap">
              {userInfo?.email}
            </p>
          </div>
          <Settings size={16} className={'text-text-1'} />
        </div>
      </div>
    </div>
  )
}

export default Sidebar
