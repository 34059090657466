import { cn } from '../../../utils/cn'

interface TableContainerProps {
  children: React.ReactNode
  className?: string
}

const TableContainer: React.FC<TableContainerProps> = ({
  children,
  className,
}) => {
  return (
    <div
      className={cn(
        'w-full flex items-start border border-panel-border rounded-lg',
        className,
      )}
    >
      {children}
    </div>
  )
}

export default TableContainer
