import { useRecoilValue, useSetRecoilState } from 'recoil'
import {
  organizationIdState,
  organizationMembersState,
} from '../../../state/state'
import useBackend from '../../services/useBackend'
import { useCallback } from 'react'

const useLoadOrganizationMembers = () => {
  const backend = useBackend()
  const organizationId = useRecoilValue(organizationIdState)
  const setOrganizationMembers = useSetRecoilState(organizationMembersState)

  const loadOrganizationMembers = useCallback(async () => {
    if (!organizationId) return
    try {
      const members = await backend.getOrganizationMembers(organizationId)
      setOrganizationMembers(members.members)
    } catch (e) {}
  }, [backend, setOrganizationMembers, organizationId])

  return loadOrganizationMembers
}

export default useLoadOrganizationMembers
