import { CirclePlus } from 'lucide-react'

const RequestCard: React.FC = () => {
  return (
    <a
      href="mailto:izak@vigilant.run"
      className="w-full flex flex-col gap-4 items-center justify-center border border-panel-border rounded-md p-4 cursor-pointer transition-colors hover:border-cta-hover"
    >
      <CirclePlus className="w-[48px] h-[48px] text-text-1" />
      <h3 className="text-[14px] font-semibold text-text-1">New Language</h3>
      <div className="w-full border-t border-panel-border" />
      <p className="text-[12px] text-center leading-4 text-text-2">
        Request a new language by contacting our support team.
      </p>
    </a>
  )
}

export default RequestCard
