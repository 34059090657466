import { useCallback, useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { projectIdState } from '../../../../state/state'
import useLoadErrors from '../../../../hooks/data/load/useLoadErrors'
import ErrorsTopbar from './ErrorsTopbar/ErrorsTopbar'
import { getTimestamps, TimeRange } from '../../../../utils/time'
import ErrorsSearchBar from './ErrorsSearchBar/ErrorsSearchBar'
import { ErrorEntry } from '../../../../backend/types'
import ErrorsContainer from './ErrorsContainer/ErrorsContainer'

export type Column = {
  name: string
  width: number
}

const Errors: React.FC = () => {
  const loadErrors = useLoadErrors()
  const projectId = useRecoilValue(projectIdState)

  const [errors, setErrors] = useState<ErrorEntry[]>([])
  console.log(errors)

  const [searchTerm, setSearchTerm] = useState('')
  const [timeRange, setTimeRange] = useState<TimeRange>('all')

  const refreshErrors = useCallback(
    async (
      searchTerm: string,
      timeRange: TimeRange,
      limit: number = 100,
      offset: number = 0,
    ) => {
      if (!projectId) return
      const [start, end] = getTimestamps(timeRange)
      const errors = await loadErrors(searchTerm, start, end, limit, offset)
      if (errors) {
        setErrors(errors)
      }
    },
    [projectId, loadErrors],
  )

  const handleRefresh = () => {
    refreshErrors(searchTerm, timeRange)
  }

  useEffect(() => {
    refreshErrors('', 'all')
  }, [refreshErrors])

  return (
    <>
      <ErrorsTopbar />
      <div className="h-full w-full flex flex-col gap-4 p-8 overflow-scroll">
        <ErrorsSearchBar
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          timeRange={timeRange}
          setTimeRange={setTimeRange}
          refresh={handleRefresh}
        />
        <div className="w-full flex-1 basis-0 flex flex-row gap-4 overflow-hidden">
          <ErrorsContainer errors={errors} />
        </div>
      </div>
    </>
  )
}

export default Errors
