import { useRecoilValue, useSetRecoilState } from 'recoil'
import {
  organizationIdState,
  organizationInfoState,
} from '../../../state/state'
import useBackend from '../../services/useBackend'
import { useCallback } from 'react'

const useLoadOrganizationInfo = () => {
  const backend = useBackend()
  const setOrganizationInfo = useSetRecoilState(organizationInfoState)
  const organizationId = useRecoilValue(organizationIdState)

  const loadOrganizationInfo = useCallback(async () => {
    if (!organizationId) return
    try {
      const organizationInfo = await backend.getOrganization(organizationId)
      setOrganizationInfo(organizationInfo)
    } catch (e) {}
  }, [backend, setOrganizationInfo, organizationId])

  return loadOrganizationInfo
}

export default useLoadOrganizationInfo
