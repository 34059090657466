import {
  Search,
  Filter as FilterIcon,
  Square,
  SquareCheckBig,
} from 'lucide-react'
import { useCallback, useEffect, useRef } from 'react'
import Button from '../../../../library/Button/Button'
import { Filter } from '../../../../../backend/types'
import FilterBlock from './FilterBlock/FilterBlock'
import TimeSelector from './TimeSelector/TimeSelector'
import { TimeRange } from '../../../../../utils/time'

interface LogSearchBarProps {
  searchTerm: string
  setSearchTerm: (searchTerm: string) => void

  timeRange: TimeRange
  setTimeRange: (timeRange: TimeRange) => void

  live: boolean
  setLive: (live: boolean) => void

  filters: Filter[]
  setFilters: (filters: Filter[]) => void

  refresh: () => void
}

const LogSearchBar: React.FC<LogSearchBarProps> = ({
  searchTerm,
  setSearchTerm,
  timeRange,
  setTimeRange,
  live,
  setLive,
  filters,
  setFilters,
  refresh,
}) => {
  const searchInputRef = useRef<HTMLInputElement>(null)

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault()
        if (searchTerm) {
          refresh()
        }
      } else if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
        searchInputRef.current?.focus()
        setSearchTerm('')
        refresh()
      } else if (event.key === 'Escape') {
        searchInputRef.current?.blur()
        setSearchTerm('')
        refresh()
      }
    },
    [searchTerm, refresh, setSearchTerm],
  )

  const handleSearchBlur = useCallback(() => {
    if (searchTerm === '') refresh()
  }, [refresh, searchTerm])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [handleKeyDown])

  return (
    <div className="flex flex-row gap-4 items-start">
      <div className="w-full flex flex-col gap-2 items-center border border-panel-border rounded-md p-2">
        <div className="w-full flex flex-row gap-2 items-center">
          <Search className="w-4 h-4 flex-none text-text-1" />
          <input
            ref={searchInputRef}
            type="text"
            className="w-full text-text-1 text-[12px] placeholder:text-text-2 font-mono"
            placeholder="Search term (e.g. 'error')"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onBlur={handleSearchBlur}
          />
        </div>
        {filters.length > 0 && (
          <div className="w-full flex flex-wrap gap-2 items-center">
            <FilterIcon className="w-4 h-4 text-text-1" />
            {filters.map((f, i) => (
              <FilterBlock
                key={`filter-${i}`}
                filter={f}
                remove={() =>
                  setFilters(filters.filter((_, index) => i !== index))
                }
              />
            ))}
          </div>
        )}
      </div>
      <Button variant={'outline'} onClick={() => setLive(!live)}>
        Live{' '}
        {live ? (
          <SquareCheckBig className="w-4 h-4" />
        ) : (
          <Square className="w-4 h-4" />
        )}
      </Button>
      <TimeSelector timeRange={timeRange} setTimeRange={setTimeRange} />
      <Button onClick={refresh}>
        Search <span className="text-[14px]">(↵)</span>
      </Button>
    </div>
  )
}

export default LogSearchBar
