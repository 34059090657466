import { LogEntry } from '../../../../../state/types'
import LogHeader from './LogHeader/LogHeader'
import LogRow from './LogRow/LogRow'
import _ from 'lodash'
import { Column } from '../Logs'

interface LogContainerProps {
  logs: LogEntry[]

  columns: Column[]
  setColumns: (columns: Column[]) => void

  selectedLog: LogEntry | undefined
  selectLog: (log: LogEntry | undefined) => void
}

const LogContainer: React.FC<LogContainerProps> = ({
  logs,
  columns,
  setColumns,
  selectedLog,
  selectLog,
}) => {
  return (
    <div className="relative flex-1 flex flex-col gap-2 text-[12px] text-text-1 border border-panel-border rounded-lg overflow-y-auto [&::-webkit-scrollbar]:hidden">
      {logs.length > 0 && columns.length > 0 && (
        <div className="flex">
          <div className="flex flex-col font-mono border-r border-panel-border">
            <LogHeader columns={columns} setColumns={setColumns} />
            {logs.map((log, i) => (
              <LogRow
                key={`log-${i}`}
                log={log}
                selected={_.isEqual(selectedLog, log)}
                selectLog={selectLog}
                columnWidths={columns.map((column) => column.width)}
                columnExtractors={columns.map((column) => getExtractor(column))}
              />
            ))}
          </div>
          <div className="w-[100px] flex-none h-full" />
        </div>
      )}
      {logs.length === 0 && (
        <div className="p-2 text-text-1 font-mono">
          No logs found for the provided query
        </div>
      )}
      {columns.length === 0 && (
        <div className="p-2 text-text-1 font-mono">
          No properties selected. Please select at least one property to view
          logs.
        </div>
      )}
    </div>
  )
}

function getExtractor(column: Column): (log: LogEntry) => React.ReactNode {
  switch (column.name) {
    case 'timestamp':
      return timestampExtractor
    case 'body':
      return bodyExtractor
    case 'level':
      return levelExtractor
    default:
      if (column.name.startsWith('attributes.')) {
        const attribute = column.name.replace('attributes.', '')
        return createAttributeExtractor(attribute)
      } else if (column.name.startsWith('resource.')) {
        const resource = column.name.replace('resource.', '')
        return createResourceExtractor(resource)
      }
      return defaultExtractor
  }
}

function timestampExtractor(log: LogEntry) {
  return formatTimestamp(log.timestamp)
}

function bodyExtractor(log: LogEntry) {
  return <pre className="whitespace-pre-wrap">{cleanValue(log.body)}</pre>
}

function levelExtractor(log: LogEntry) {
  return (
    <pre className="whitespace-pre-wrap font-light">
      {cleanValue(log.severity_name || '-')}
    </pre>
  )
}

function createResourceExtractor(resource: string) {
  return (log: LogEntry) => (
    <pre className="whitespace-pre-wrap font-light">
      {cleanValue(log.resource?.[resource] || '-')}
    </pre>
  )
}

function createAttributeExtractor(attribute: string) {
  return (log: LogEntry) => (
    <pre className="whitespace-pre-wrap font-light">
      {cleanValue(log.attributes?.[attribute] || '-')}
    </pre>
  )
}

function cleanValue(input: any) {
  if (typeof input !== 'string') return input
  // eslint-disable-next-line no-control-regex
  return input.replace(/(?:\u001b\[)[0-9;]*m/g, '')
}

function defaultExtractor(log: LogEntry) {
  return '-'
}

function formatTimestamp(timestamp: Date) {
  const date = new Date(timestamp)
  return date
    .toLocaleDateString('en-US', {
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    })
    .replace(',', '')
}

export default LogContainer
