import { X } from 'lucide-react'

interface PopupCloseProps {
  onClick: () => void
}

const PopupClose: React.FC<PopupCloseProps> = ({ onClick }) => {
  return (
    <X
      className="w-9 h-9 px-2 cursor-pointer text-text-1 hover:bg-panel-background rounded-lg"
      onClick={onClick}
      role="button"
    />
  )
}

export default PopupClose
