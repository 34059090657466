import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import './App.css'
import './index.css'
import { RecoilRoot } from 'recoil'
import Dashboard from './components/pages/Dashboard/Dashboard'
import Signup from './components/pages/Signup/Signup'
import Login from './components/pages/Login/Login'
import NewProject from './components/pages/NewProject/NewProject'
import Invite from './components/pages/Invite/Invite'
import API from './components/pages/Dashboard/API/API'
import Logs from './components/pages/Dashboard/Logs/Logs'
import Integrations from './components/pages/Dashboard/Integrations/Integrations'
import Settings from './components/pages/Dashboard/Settings/Settings'
import AccountSettings from './components/pages/Dashboard/Settings/AccountSettings/AccountSettings'
import OrganizationSettings from './components/pages/Dashboard/Settings/OrganizationSettings/OrganizationSettings'
import ProjectSettings from './components/pages/Dashboard/Settings/ProjectSettings/ProjectSettings'
import NewOrganization from './components/pages/NewOrganization/NewOrganization'
import Posthog from './components/library/Posthog/Posthog'
import Errors from './components/pages/Dashboard/Errors/Errors'

function App() {
  return (
    <RecoilRoot>
      <Posthog>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Dashboard />}>
              <Route index element={<Navigate to="logs" replace />} />
              <Route path="logs" element={<Logs />} />
              <Route path="errors" element={<Errors />} />
              <Route path="api" element={<API />} />
              <Route path="integrations" element={<Integrations />} />
              <Route path="settings" element={<Settings />}>
                <Route index element={<Navigate to="organization" replace />} />
                <Route path="account" element={<AccountSettings />} />
                <Route path="organization" element={<OrganizationSettings />} />
                <Route path="project" element={<ProjectSettings />} />
              </Route>
            </Route>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/organization" element={<NewOrganization />} />
            <Route path="/project" element={<NewProject />} />
            <Route path="/invite" element={<Invite />} />
          </Routes>
        </BrowserRouter>
      </Posthog>
    </RecoilRoot>
  )
}

export default App
