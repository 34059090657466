import GoDocs from './Go/GoDocs'
import PythonDocs from './Python/PythonDocs'
import NodeDocs from './Node/NodeDocs'

interface DocsProps {
  name: Integration
  close: () => void
}

export type Integration = 'go' | 'node' | 'python'

const Docs: React.FC<DocsProps> = ({ name, close }) => {
  switch (name) {
    case 'go':
      return <GoDocs close={close} />
    case 'node':
      return <NodeDocs close={close} />
    case 'python':
      return <PythonDocs close={close} />
    default:
      return null
  }
}

export default Docs
