import { PropsWithChildren } from 'react'
import { usePosthogInit } from '../../../hooks/utils/usePosthogInit'
import { usePosthogIdentify } from '../../../hooks/utils/usePosthogIdentify'

const Posthog: React.FC<PropsWithChildren> = ({ children }) => {
  usePosthogInit()
  usePosthogIdentify()
  return <>{children}</>
}

export default Posthog
