import { atom } from 'recoil'
import {
  APIKeyInfo,
  OrganizationInfo,
  ProjectInfo,
  TokenInfo,
  UserInfo,
} from './types'
import persistAtom from './persistAtom'
import { OrganizationMember } from '../backend/types'

export const userInfoState = atom<UserInfo>({
  key: 'userInfo',
  default: { id: '', email: '' },
})

export const organizationIdState = atom<string | null>(
  persistAtom({
    key: 'organizationId',
    default: null,
    persistMode: 'local',
  }),
)

export const organizationInfoState = atom<OrganizationInfo | null>({
  key: 'organizationInfo',
  default: null,
})

export const organizationMembersState = atom<OrganizationMember[] | null>({
  key: 'organizationMembers',
  default: null,
})

export const organizationsState = atom<OrganizationInfo[] | null>({
  key: 'organizations',
  default: null,
})

export const joinOrganizationTokenState = atom<string | null>({
  key: 'joinOrganizationToken',
  default: null,
})

export const projectIdState = atom<string | null>(
  persistAtom({
    key: 'projectId',
    default: null,
    persistMode: 'local',
  }),
)

export const projectsState = atom<ProjectInfo[] | null>({
  key: 'projects',
  default: null,
})

export const apiKeysState = atom<APIKeyInfo[]>({
  key: 'apiKeys',
  default: [],
})

export const tokensState = atom<TokenInfo[]>({
  key: 'tokens',
  default: [],
})
