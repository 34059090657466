import useLoggedOut from '../../../hooks/utils/useLoggedOut'
import useInitAdminData from '../../../hooks/data/effects/useInitAdminData'
import useProjectSelected from '../../../hooks/utils/useProjectSelected'
import { Outlet } from 'react-router-dom'
import Sidebar from './Sidebar/Sidebar'

const Dashboard: React.FC = () => {
  const loggedOut = useLoggedOut()

  useInitAdminData()
  useProjectSelected()

  if (loggedOut) return <></>

  return (
    <div className="h-screen w-screen flex items-start justify-start bg-background overflow-hidden">
      <Sidebar />
      <div className="h-full flex-1 flex flex-col max-h-full items-center justify-start overflow-scroll">
        <Outlet />
      </div>
    </div>
  )
}

export default Dashboard
