import { useRecoilValue } from 'recoil'
import {
  organizationIdState,
  organizationsState,
  projectIdState,
  projectsState,
} from '../../state/state'
import useNav, { LinkKey } from './useNav'
import { useCallback, useEffect } from 'react'
import useSwitchOrganization from '../actions/useSwitchOrganization'
import useSwitchProject from '../actions/useSwitchProject'

const useProjectSelected = () => {
  const navigate = useNav()

  const organizations = useRecoilValue(organizationsState)
  const projects = useRecoilValue(projectsState)

  const organizationId = useRecoilValue(organizationIdState)
  const projectId = useRecoilValue(projectIdState)

  const switchOrganization = useSwitchOrganization()
  const switchProject = useSwitchProject()

  const selectProject = useCallback(async () => {
    if (organizations === null) return
    if (organizations.length === 0) {
      navigate(LinkKey.NewOrganization)
      return
    }

    if (organizationId === null) {
      const organizationId = organizations[0].id
      await switchOrganization(organizationId)
    } else if (projects !== null && projectId === null) {
      const projectId = projects[0].id
      await switchProject(projectId)
    }
  }, [
    organizations,
    projects,
    organizationId,
    projectId,
    switchOrganization,
    switchProject,
    navigate,
  ])

  useEffect(() => {
    selectProject()
  }, [selectProject])

  return selectProject
}

export default useProjectSelected
